.control {
    font-family: arial;
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 20px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 16px;
    padding: 18px;
}

.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.control_indicator {
    position: absolute;
    top: 40px;
    left: 20px;
    height: 24px;
    width: 24px;
    background: transparent;
    border: 2px solid #F1973D;
    border-radius: undefinedpx;
}

.control:hover input~.control_indicator,
.control input:focus~.control_indicator {
    background: #986BE2;
}

.control input:checked~.control_indicator {
    background: transparent;
}

.control input:disabled~.control_indicator {
    background: #e6e6e6;
    opacity: 1;
    pointer-events: none;
}

.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}

.control input:checked~.control_indicator:after {
    display: block;
}

.control-radio .control_indicator {
    border-radius: 50%;
}

.control-radio .control_indicator:after {
    left: 2px;
    top: 2px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #F1973D;
    transition: background 250ms;
}

.control-radio input:disabled~.control_indicator:after {
    background: #7b7b7b;
}

.control-radio .control_indicator::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.5rem;
    height: 4.5rem;
    margin-left: -1.6rem;
    margin-top: -1.6rem;
    background: #F1973D;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
}

@keyframes s-ripple {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    20% {
        transform: scale(1);
    }

    100% {
        opacity: 0.01;
        transform: scale(1);
    }
}

@keyframes s-ripple-dup {
    0% {
        transform: scale(0);
    }

    30% {
        transform: scale(1);
    }

    60% {
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

.control-radio input+.control_indicator::before {
    animation: s-ripple 250ms ease-out;
}

.control-radio input:checked+.control_indicator::before {
    animation-name: s-ripple-dup;
}