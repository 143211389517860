.advantages_section {
    font-family: 'InterRegular', sans-serif;
    position: relative;
    z-index: 4;
}

.gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 30%;
}

.gradient::after {
    content: '';
    position: absolute;
    right: 5%;
    top: 5%;
    width: 400px;
    height: 400px;
    background: linear-gradient(135deg, #DCC880 0%, #F5BD2C 47.77%, #EBCE37 100%);
    mix-blend-mode: overlay;
    border-radius: 50%;
    filter: blur(50px);
    z-index: -1;
}

.container {
    position: relative;
    max-width: 85%;
    margin: 0 auto;
    padding: 100px 0;
    z-index: 4;
}

.section_title {
    color: var(--orange);
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
    font-family: 'PoppinsRegular', sans-serif;
    font-weight: 600;
}

.section_title_des {
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
    color: #F3F4F8;
    margin: 0 auto;
}

.advantages_list {
    display: flex;
    margin: 40px 0;
    justify-content: center;
}

.advantages_card {
    display: flex;
    flex-direction: column;
    flex: 0 1 23%;
    margin: 20px 0;
    border-right: 4px solid #748fb53e;
    padding: 20px;
}

.advantages_card:last-child {
    border-right: none;
}

.advantages_card img {
    width: 64px;
    height: 64px;
    margin: 0 auto;
    align-items: center;
}


.advantages_card span {
    margin-top: 16px;
    text-align: center;

}

@media (min-width: 1921px) {

    .container {
        max-width: 1440px;
    }

}

@media (max-width: 767px) and (min-width: 340px) {

    .container {
        max-width: 100%;
    }

    .section_title {
        font-size: 14px;
        letter-spacing: 0.7px;
    }

    .section_title_des {
        font-size: 24px;
        line-height: 36px;
    }

    .advantages_list {
        flex-wrap: wrap;
        margin: 0;
        font-size: 14px;
    }

    .advantages_card {
        flex: 0 1 48%;
        border-right: none;
        padding: 0 10px;
    }

}