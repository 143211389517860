.links_wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: fixed;
    bottom: 90px;
    right: 25px;
    z-index: 49;
}

.links_wrapper a {
    flex: 0 1 23%;
    width: 64px;
}

.social_imgs {
    width: 100%;
}

.links_wrapper span {
    cursor: pointer;
}