.verify_wrapper {
    position: fixed;
    background-color: #1C1D1F;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 110;
    text-align: center;
}

.container {
    width: 35%;
    min-height: 600px;
    background-color: #FFFFFF1A;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    padding: 38px 48px;
    transition: 0.4s;
}

.verify_title {
    font-size: 48px;
    font-weight: 600;
    font-family: 'PoppinsSemiBold', sans-serif;
    color: #fff;
    text-align: center;
    margin-bottom: 40px;
}

.verify_info {
    font-family: 'InterMedium', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #B1B1B5;
    width: 80%;
    margin: 0 auto;
}

.btns_wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 250px;
}

.btns_wrapper button {
    margin-top: 20px;
}

.confirm_btn {
    height: 56px;
    border-radius: 16px;
    background: #986BE2;
    color: #fff;
    font-family: 'InterBold', sans-serif;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    border: none;
}

.confirm_btn:active {
    background-color: transparent;
    border: 2px solid #986BE2;
}

.again_btn {
    margin-top: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #B1B1B5;
}

.again_btn:active {
    color: #986BE2;
}

.error {
    color: rgb(255, 0, 0);
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 13px;
    display: block;
    width: 100%;
    margin: 10px 0;
    text-align: center;
}

@media(max-width: 1440px) {
    .container {
        width: 45%;
    }
}

@media(max-width: 1280px) {
    .container {
        width: 55%;
    }

    .verify_title {
        font-size: 38px;
    }
}

@media(max-width: 992px) {
    .container {
        width: 75%;
    }
}

@media(max-width: 767px) {
    .container {
        width: 95%;
    }

    .verify_title {
        font-size: 30px;
    }
}

@media(max-width: 590px) {
    .verify_title {
        font-size: 26px;
    }

    .verify_info {
        font-size: 14px;
        line-height: 22px;
    }
}