.technologies_section {
    background: #141416;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'PoppinsRegular', sans-serif;
}

.container {
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 100%;
}

.section_title {
    text-transform: uppercase;
    color: #f1973d;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.8px;
    margin-bottom: 16px;
}

.section_title_des {
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 24px;

}

.technology_span {
    text-transform: lowercase;
}

.description {
    color: #6E757C;
    font-family: 'InterRegular', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.rightSide {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    flex: 0 1 40%;
}

.icon_block {
    background-color: #2B2C2E;
    padding: 32px;
    border-radius: 24px;
    flex: 0 1 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 128px;
    height: 128px;
    object-fit: contain;
    object-position: center;
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width: 1200px) {
    .rightSide {
        flex: 0 1 50%;
    }

    .icon_block {
        padding: 12px;
    }
}

@media(max-width: 992px) {
    .leftSide {
        flex: 0 1 48%;
    }

    .section_title_des {
        font-size: 30px;
    }

    .rightSide {
        gap: 10px;
    }

    .icon_block {
        flex: 0 1 45%;
    }


}

@media(max-width: 767px) {
    .container {
        flex-direction: column;
        text-align: center;
    }

    .section_title_des {
        font-size: 26px;
        line-height: 30px;
    }

    .leftSide {
        margin-bottom: 30px;
    }

    .rightSide {
        flex: 0 1 100%;
        justify-content: space-between;
    }

    .icon_block {
        flex: 0 1 23%;
        margin-bottom: 20px;
    }

    /* .icon {
        width: 60px;
        height: 60px;
    } */
}

@media(max-width: 430px) {
    .technologies_section {
        min-height: 80vh;
    }

    .section_title_des {
        font-size: 24px;
    }

    .icon_block {
        flex: 0 1 45%;
    }

    .icon {
        width: 90px;
        height: 90px;
    }


}