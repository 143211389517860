.employment_section {
    font-family: 'PoppinsRegular', sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    max-width: 85%;
    margin: 0 auto;
}

.textContent {
    max-width: 55%;
}

.section_title {
    color: #F1973D;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.title_description {
    color: #FFF;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    margin-bottom: 12px;
    width: 100%;
}

.description {
    color: #B1B1B5;
    font-family: 'InterRegular', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
}

.employeesCards {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
}

.employersCard {
    flex: 0 1 23%;
}

.employersCard img {
    width: 100%;
    object-fit: contain;
    object-position: center;
}

@media (min-width: 1921px) {

    .container {
        max-width: 1440px;
    }

}

@media (min-width: 768px) and (max-width: 1280px) {
    .textContent {
        max-width: 100%;
    }

    .title_description {
        font-size: 36px;
        line-height: 36px;
        margin-bottom: 16px;
    }

    .description {
        font-size: 18px;
    }

    .employeesCards {
        gap: 24px;
        justify-content: space-between;
    }

    .employersCard {
        flex: 0 1 35%;
    }
}

@media (max-width: 767px) and (min-width: 340px) {
    .container {
        max-width: 100%;
        padding: 0 16px;
    }

    .textContent {
        max-width: 100%;
    }

    .section_title {
        font-size: 14px;
        letter-spacing: 0.7px;
        margin-bottom: 8px;
    }

    .title_description {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
    }

    .description {
        font-size: 16px;
        font-weight: 400;
    }

    .employeesCards {
        margin-top: 40px;
        gap: 24px;
        justify-content: space-between;
    }

    .employersCard {
        flex: 0 1 45%;
    }
}