.container {
    max-width: 85%;
    margin: 0 auto;
}

.goBack_title_block {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 30px 0;
}

.goBack_title_block img {
    cursor: pointer;
    border-radius: 50%;
    background-color: #5c5e626e;
    border: 1px solid #5c5e62;
}

.title {
    font-family: 'PoppinsSemiBold', sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #F3F4F8;
}

.videos_wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width: 992px) {
    .title {
        font-size: 30px;
    }

    .videos_wrapper {
        gap: 0;
        justify-content: space-between;
    }
}

@media(max-width: 767px) {}