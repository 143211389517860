.wrapper {
    background-color: #2B2C2E;
    flex: 0 1 15%;
    padding: 10px;
    border-radius: 12px;
    position: relative;
    right: 40px;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    cursor: pointer;
    user-select: none;
}

.avatar {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
}

.userName {
    font-family: 'InterSemiBold', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
}

.arrows {}


@media(max-width: 1440px) {
    .wrapper {
        flex: 0 1 20%;
    }
}

@media(max-width: 1280px) {
    .wrapper {
        flex: 0 1 25%;
    }
}

@media(max-width: 1024px) {
    .wrapper {
        flex: 0 1 33%;
    }
}

@media(max-width: 767px) {
    .wrapper {
        flex: 0 1 45%;
        /* right: 35px; */
    }
}

@media(max-width: 470px) {
    .wrapper {
        flex: 0 1 55%;
    }

    .container {
        justify-content: flex-start;
        gap: 10px;
    }

    .arrows {
        display: none;
    }
}