.courses_section {
    min-height: 100vh;
    padding: 100px 0;
    font-family: 'PoppinsRegular', sans-serif;
}

.container {
    max-width: 85%;
    margin: 0 auto;
}

.section_title {
    text-transform: uppercase;
    color: #f1973d;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.8px;
    margin-bottom: 16px;
}

.section_title_des {
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 24px;

}

.courses_cards {
    margin-top: 64px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    font-family: 'InterRegular', sans-serif;
}

.card {
    flex: 0 1 48%;
    background-color: #2B2C2E;
    padding: 50px 10px 50px 50px;
    border: none;
    border-radius: 20px;
}

.topBlock {
    display: flex;
    justify-content: space-between;
}

.topLeft {
    flex: 0 1 25%;
}

.topRight {
    margin-top: 2%;
    flex: 0 1 65%;

}

.description {
    margin-top: 12px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #B1B1B5;
    width: 80%;
}

.card_icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.first_icon {
    background-color: #3a3b3d;
    border-radius: 24px;
    width: 128px;
    height: 128px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    width: 80%;
    height: 80%;
    object-fit: contain;
    object-position: center;
    border-radius: 24px;
}

.courseName {
    margin-top: 24px;
}



.detailBtn {
    margin-top: 60px;
    background-color: #F1973D;
    color: #fff;
    padding: 16px 32px;
    border: none;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    min-width: 40%;

    cursor: pointer;
}

.detailBtn:active {
    background-color: transparent;
    border: 1px solid #F1973D;
}

@media (min-width: 1921px) {

    .container {
        max-width: 1440px;
    }

}

@media (min-width: 768px) and (max-width: 1023px) {
    .courses_section {
        padding: 70px 16px;
    }

    .section_title {
        letter-spacing: 0.7px;
        margin-bottom: 12px;
    }

    .section_title_des {
        font-size: 40px;
        line-height: 36px;
    }

    .courses_cards {
        margin-top: 40px;
        flex-direction: column;
    }

    .card {
        padding: 24px 16px;
    }

    .topBlock {
        padding: 0 24px;
    }

    .topRight {
        flex: 0 1 60%;

    }

    .description {
        width: 90%;
    }


    .detailBtn {
        margin-top: 40px;
        padding: 16px 24px;
        min-width: 100%;
    }
}


@media (max-width: 767px) and (min-width: 340px) {
    .courses_section {
        padding: 70px 16px;
    }

    .container {
        max-width: 100%;
    }

    .section_title {
        font-size: 14px;
        letter-spacing: 0.7px;
        margin-bottom: 12px;
    }

    .section_title_des {
        font-size: 24px;
        line-height: 36px;
    }

    .courses_cards {
        margin-top: 40px;
        flex-direction: column;
    }

    .card {
        padding: 24px 16px;
    }

    .topLeft {
        flex: 0 1 40%;
    }

    .topRight {
        flex: 0 1 50%;

    }

    .description {
        margin-top: 0px;
        font-size: 16px;
        width: 100%;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        line-clamp: 6;
        box-orient: vertical;
    }

    .first_icon {
        width: 122px;
        height: 122px;
    }

    .courseName {
        margin-top: 26px;
    }



    .detailBtn {
        margin-top: 40px;
        padding: 16px 24px;
        min-width: 100%;
    }

}