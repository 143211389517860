header {
    background-color: #2B2C2E4D;
    z-index: 50;
}

.container {
    width: 85%;
    margin: 0 auto;
}

.header {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo_block {
    flex: 0 1 12%;
}

.logo {
    width: 83px;
    height: 53px;
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width: 1280px) {
    .logo_block {
        flex: 0 1 10%;
    }
}