.thanks_wrapper {
    min-height: 100vh;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90vh;
}


h1 {
    font-family: 'PoppinsSemiBold', sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 56px;
    color: #fff;
    margin-bottom: 10px;
}

.desc {
    font-family: 'InterMedium', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #B1B1B5;
}

.img_block {
    flex: 0 1 50%;
    overflow: hidden;
}


.loading {
    text-align: center;
    margin: 150px 0;
}

.error {
    color: red;
    text-align: center;
    margin: 150px 0;
}

@media(max-width: 1400px) {
    .container {
        max-width: 85%;
    }
}

@media(max-width: 1040px) {
    .img_block img {
        width: 100%;
    }
}

@media(max-width: 767px) {
    .container {
        margin-top: 50px;
        flex-direction: column-reverse;
        height: auto;
    }
}

@media(max-width: 500px) {
    .container {
        max-width: 95%;
        text-align: center;
    }
}