.portfolioSection {
    min-height: 100vh;
    background: #141416;
    position: relative;
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    position: relative;
    overflow: hidden;

}

.portfolioSection::before {
    content: '';
    position: absolute;
    right: 5%;
    top: 7%;
    width: 50%;
    height: 50%;
    background: linear-gradient(135deg, #DCC880 0%, #F5BD2C 47.77%, #EBCE37 100%);
    mix-blend-mode: soft-light;
    border-radius: 50%;
    filter: blur(50px);
    z-index: 0;
    animation: gradient 5s linear infinite;
}

@keyframes gradient {

    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg) scale(1.2);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

.container {
    position: relative;
    z-index: 2;
    max-width: 85%;
    margin: 0 auto;
    padding: 80px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftSide {
    flex: 0 1 40%;
}

.section_title {
    color: #F1973D;
    font-size: 16px;
    font-style: normal;
    font-family: 'PoppingRegular', sans-serif;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-bottom: 16px;
}


.section_title_des {
    color: #F3F4F8;
    font-size: 40px;
    font-family: 'PoppingRegular', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    margin-bottom: 12px;
}

.description {
    color: #76797D;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.actions {
    display: flex;
    flex-direction: column;
}

.allProjects_btn {
    background: #986BE2;
    margin-top: 30px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    line-height: normal;
    padding: 21px;
    border: none;
    border-radius: 16px;
    max-width: 50%;
    cursor: pointer;
}

.allProjects_btn:active {
    background-color: transparent;
    border: 1px solid #986BE2;
}

.rightSide {
    flex: 0 1 50%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.leftCards {
    position: relative;
    top: -40px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.rightCards {
    position: relative;
    top: 40px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.card {
    width: 255px;
    height: 360px;
    overflow: hidden;
    border-radius: 25px;
    margin-bottom: 30px;
    transition: 0.3s;
}

.card:hover {
    box-shadow: 0px 0px 40px 1px #986BE2;
}

.card_link {
    display: block;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    font-family: 'InterSemiBold', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    letter-spacing: 0.8px;
    text-shadow: 1px 1px 10px black;
}

.project_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.student_name {
    position: absolute;
    top: 20px;
    left: 20px;
    font-family: inherit;
}

.project_name {
    position: absolute;
    left: 20px;
    bottom: 20px;
}

@media (min-width: 1921px) {

    .container {
        max-width: 1440px;
    }

}

@media (max-width: 1280px) and (min-width: 768px) {
    .portfolioSection::before {
        right: 2%;
        width: 80%;
        height: 80%;
    }

    .container {
        flex-direction: column-reverse;
    }

    .leftSide {
        flex: 1 0 50%;
    }

    .description {
        display: inline-block;
        max-width: 80%;
    }


    .allProjects_btn {
        max-width: 80%;
    }

    .rightSide {
        flex-wrap: nowrap;
        gap: 16px;
    }

    .card {
        width: 100%;
    }
}


@media (max-width: 768px) and (min-width: 340px) {
    .portfolioSection::before {
        left: 0;
        top: 10%;
        width: 120%;
        height: 90%;
        filter: blur(60px);
    }

    .container {
        max-width: 100%;
        padding: 70px 16px;
        flex-direction: column-reverse;
    }


    .section_title {
        font-size: 14px;
        letter-spacing: 0.7px;
        margin-bottom: 12px;
    }

    .section_title_des {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
    }

    .description {
        font-size: 16px;
        line-height: 24px;
    }

    .allProjects_btn {
        margin-top: 54px;
        padding: 21px 16px;
        max-width: 100%;
    }

    .rightSide {
        flex: 0 1 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .leftCards {
        position: inherit;
        top: 0;

        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .rightCards {
        position: inherit;
        top: 0;

        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .card {
        width: 50%;
        flex: 0 1 100%;
        max-height: 20%;
    }

    .card_link {
        font-size: 16px;
        letter-spacing: 0.7px;
    }

}