.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.container {
    width: 50%;
    min-height: 60%;
    background-color: #2B2C2E;
    border-radius: 32px;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 28px 40px 40px;
}

.title_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    text-transform: uppercase;
    font-style: normal;
    font-family: 'PoppinsSemiBold', sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
    color: #F1973D;
}

.close {
    cursor: pointer;
}

.under_title {
    font-family: 'PoppinsSemiBold', sans-serif;
    font-size: 40px;
    color: #F3F4F8;
    font-weight: 600;
    line-height: 42px;
    margin-bottom: 10px;
}

.under_title_text {
    font-family: 'InterMedium', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #B1B1B5;
    font-weight: 500;
}

.area {
    resize: none;
    width: 100%;
    height: 160px;
    background-color: #3A3B3D;
    border: none;
    border-radius: 16px;
    padding: 20px;
    transition: 0.2s;
    font-size: 16px;
    font-weight: 600;
    font-family: 'InterSemiBold', sans-serif;
    color: #fff;
    letter-spacing: 0.5px;
}

.area::placeholder {
    color: #76797D;
}

.area:focus {
    outline: 1px solid #986BE2;
}

.save_btn {
    width: 100%;
    height: 64px;
    margin: 20px 0 0;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    background-color: #986BE2;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    transition: 0.1s;
}

.save_btn:active {
    background-color: transparent;
    border: 2px solid #986BE2;
}

.error {
    color: rgb(255, 0, 0);
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 13px;
    display: block;
    width: 100%;
    margin: 10px 0;
    text-align: center;
}

@media(max-width:1024px) {
    .under_title {
        font-size: 30px;
    }

    .under_title_text {
        margin-bottom: 20px;
    }
}

@media(max-width:767px) {
    .container {
        width: 95%;
    }
}