.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 120;
    backdrop-filter: blur(10px);
}

.container {
    width: 50%;
    min-height: 50%;
    background-color: #2B2C2E;
    border-radius: 32px;
    border: 2px solid rgba(255, 255, 255, 0.10);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.25);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 28px 40px 40px;
    z-index: 120 !important;
}

.title_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-family: 'InterSemiBold', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #F3F4F8;
}

.close {
    cursor: pointer;
    z-index: 100;
}

.photo_block {
    display: flex;
    align-items: center;
}

.photo_block img {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    margin-right: 24px;
    object-fit: cover;
    object-position: center;
}

.photo_block button {
    cursor: pointer;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    font-size: 14px;
    flex: 0 1 20%;
}

.add_photo::file-selector-button {
    cursor: pointer;
    border: none;
    height: 48px;
    border-radius: 12px;
    color: #fff;
    background-color: #986BE2;
    padding: 5px 10px;
    transition: 0.3s;
    /* font-family: 'InterBold', sans-serif; */
    font-weight: 700;
    letter-spacing: 0.5px;
    font-size: 14px;
}

.add_photo::file-selector-button:active {
    background-color: transparent;
    border: 1px solid #986BE2;
}

.delete_photo {
    background-color: transparent;
    border: none;
    color: #ED5158;
    height: 48px;
    letter-spacing: 0.5px;
}

.info_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.info_block__item {
    flex: 0 1 47%;
    margin-bottom: 12px;
}

.info_block__item h5 {
    text-align: left;
    margin-bottom: 10px;
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #B1B1B5;
}

.info {
    width: 100%;
    height: 52px;
    padding: 16px;
    background-color: #3A3B3D;
    border-radius: 16px;
    border: none;
    outline: none;
    color: #F3F4F8;
    text-align: left;
}

.btns_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.btns_wrapper button {
    cursor: pointer;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    font-size: 14px;
    flex: 0 1 20%;
}

.save {
    background-color: #986BE2;
    height: 48px;
    border-radius: 12px;
    border: none;
    transition: 0.3s;
    color: #fff;
}

.save:active {
    border: 1px solid #986BE2;
    background-color: transparent;
}

.change {
    background-color: transparent;
    height: 48px;
    border: none;
    color: #F1973D;
}

.error {
    color: rgb(255, 0, 0);
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 13px;
    display: block;
    width: 100%;
    margin: 10px 0;
    text-align: center;
}

@media(max-width: 1280px) {
    .container {
        width: 70%;
    }
}

@media(max-width: 992px) {
    .container {
        width: 80%;
    }
}

@media(max-width: 767px) {
    .wrapper {
        z-index: 2000;
    }

    .container {
        width: 95%;
        min-height: 90%;
    }

    .photo_block {
        flex-direction: column;
        margin-top: 20px;
    }

    .add_photo {
        height: 60px;
        margin: 20px 0 10px;
    }

    .info_block__item {
        flex: 0 1 100%;
    }

    .btns_wrapper button {
        flex: 0 1 100%;
        margin-top: 10px;
    }
}

@media(max-width: 470px) {
    .title {
        font-size: 18px;
    }

    .container {
        width: 100%;
        min-height: 95%;
    }

    .delete_photo {
        height: 20px;
        margin-bottom: 20px;
    }
}