.container {
    max-width: 85%;
    margin: 0 auto;
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 60px;
    flex-wrap: wrap;
    padding: 40px 0;
}

.title {
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #fff;
    letter-spacing: 1px;
    margin: 20px 0;
    user-select: none;
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width: 1280px) and (min-width: 768px) {
    .wrapper>.card {
        flex: 0 1 45%;
    }
}

@media(max-width: 767px) {
    .title {
        text-align: center;
        text-shadow: 0px 1px 30px var(--violet);
    }
}