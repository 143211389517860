.hero_section {
    min-height: 100vh;
    position: relative;
    font-family: 'InterRegular', sans-serif;
    z-index: 0;
}

.hero_section::before {
    content: '';
    position: absolute;
    left: 5%;
    top: 10%;
    width: 900px;
    height: 800px;
    background: linear-gradient(135deg, #AE80DC 0%, #DC83C3 47.77%, #8084DC 100%);
    border-radius: 50%;
    filter: blur(50px);
    z-index: 1;
    animation: gradient 5s linear infinite;
}

@keyframes gradient {

    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg) scale(1.2);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

.dark_bg {
    background-color: rgba(0, 0, 0, 0.753);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.container {
    max-width: 85%;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.block {
    flex: 0 1 45%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.main_title {
    z-index: 1;
    font-size: 72px;
    font-weight: 600;
    line-height: 80px;
    font-family: 'PoppinsRegular', sans-serif;
    font-weight: 700;
}

.title_description {
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    color: var(--grey);
    margin: 20px 0 30px;
    max-width: 80%;
}

.rightSide {
    flex: 0 1 35%;
}

.rightSide img {
    width: 100%;
    object-fit: contain;
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width: 1280px) {
    .main_title {
        font-size: 60px;
    }

    .hero_section::before {
        width: 60%;
        height: 70%;
    }

    .block {
        align-items: flex-start;
    }
}

@media(max-width: 1024px) {
    .hero_section::before {
        width: 50%;
        height: 50%;
    }
}

@media(max-width: 992px) {
    .main_title {
        font-size: 40px;
    }

    .title_description {
        font-size: 18px;
    }
}

@media(max-width: 767px) {
    .hero_section {
        min-height: 80vh;
    }

    .container {
        flex-direction: column-reverse;
        justify-content: flex-start;
        height: auto;
    }

    .main_title {
        display: none;
        text-align: center;
        width: 100%;
    }

    .title_description {
        text-align: center;
        margin: 80px auto 30px;
    }

    .rightSide img {
        margin-top: 20px;
        width: 350px;
        height: 350px;
    }
}