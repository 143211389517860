.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    backdrop-filter: blur(5px);
}

.container {
    width: auto;
    height: auto;
    background-color: #2B2C2E;
    padding: 50px 30px;
    border-radius: 20px;
}

.thanks {
    font-family: 'PoppinsSemiBold', sans-serif;
    font-weight: 600;
    font-size: 30px;
    color: var(--orange);
    text-align: center;
}