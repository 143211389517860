* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'PoppinsRegular', sans-serif;
    color: white;


}

.home_section {
    background: #1C1D1F;
    position: relative;
}