.btns_wrapper {
    display: flex;
    gap: 10px;
}

.login {
    color: #B1B1B5;
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    padding: 16px 24px;
    cursor: pointer;
}

.reg {
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    padding: 16px 24px;
    background-color: #986BE2;
    border-radius: 16px;
    border: none;
    cursor: pointer;
}


@media(max-width: 767px) {
    .btns_wrapper {
        position: relative;
        right: 50px;
    }

    .login {
        padding: 16px 10px;
    }

    .reg {
        display: none;
    }
}