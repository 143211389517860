.technology_callback {
    padding: 32px 0;
    background-color: #2B2C2E;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.container {
    max-width: 85%;
    margin: 0 auto;
    font-family: 'PoppinsRegular', sans-serif;

    display: flex;
    flex-direction: column;
    z-index: 2;
}


.dark_bg {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
}

.title {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #F1973D;
}

.title_description {
    text-align: center;
    color: #FFF;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
}

.description {
    color: #B1B1B5;
    font-family: 'InterRegular', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width:1285px) {
    .title_description {
        font-size: 35px;
    }

    .description {
        text-align: center;
    }
}

@media(max-width:450px) {
    .title_description {
        line-height: 35px;
        margin: 15px;
    }
}