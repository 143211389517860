.container {
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    column-gap: 64px;
    text-align: center;
}

.titles_block {
    margin-top: 50px;
}

.title {
    font-family: 'PoppinsSemiBold', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #F1973D;
    letter-spacing: 0.8px;
    margin-bottom: 10px;
    line-height: 20px;
}

.under_title {
    font-family: 'PoppinsSemiBold', sans-serif;
    color: #F3F4F8;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    margin-bottom: 20px;
}

.mentors_info {
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #B1B1B5;
    width: 70%;
    margin: 0 auto;
}

.mentors_block {
    margin: 64px auto;
    width: 80%;
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
}

.mentor_card {
    flex: 0 1 23%;
    background-color: #2B2C2E;
    border-radius: 24px;
    border: 1px solid #3A3B3D;
    padding: 48px 20px;
    cursor: pointer;
    transition: 0.3s;
    margin-bottom: 20px;
}

.mentor_card:hover {
    box-shadow: 0px 0px 20px 1px #986BE2;
}

.mentor_avatar {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.mentor_name {
    margin: 30px 0 10px;
    height: 24px;
    font-family: 'PoppinsSemiBold', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #F3F4F8;
    letter-spacing: 0.5px;
}

.mentor_city {
    margin-top: 10px;
    height: 24px;
    font-family: 'PoppinsSemiBold', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #F3F4F8;
    letter-spacing: 0.5px;
}

.work_place {
    margin-bottom: 5px;
    color: #F1973D;
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    height: 24px;
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

/* @media(max-width:1200px) {
    .mentor_card {
        flex: 0 1 30%;
    }
}

@media(max-width:992px) {
    .mentor_card {
        flex: 0 1 30%;
    }
}

@media(max-width:830px) {
    .mentor_card {
        flex: 0 1 48%;
    }
}

@media(max-width:388px) {
    .mentor_card {
        flex: 0 1 100%;
    }
} */

@media(max-width: 992px) {
    .under_title {
        font-size: 30px;
    }

    .mentors_info {
        width: 70%;
    }

    .mentors_block {
        gap: 10px;
    }
}

@media(max-width: 767px) {
    .container {
        max-width: 95%;
    }

    .under_title {
        font-size: 26px;
        line-height: 35px;
    }

    .mentors_info {
        width: 70%;
        font-size: 16px;
        line-height: 24px;
    }

    .mentors_block {
        justify-content: space-between;
    }

    .mentor_card {
        flex: 0 1 48%;
    }
}

@media(max-width: 520px) {
    .mentor_card {
        flex: 0 1 100%;
    }
}