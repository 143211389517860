.feedbackSwiper {
    background-color: transparent;
    width: 50%;
    height: 50%;
}


.swiper-slide {
    background-color: #2B2C2E;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    border-radius: 16px;
}

.feedbackSlider {
    background-color: #2B2C2E;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    border-radius: 16px;
}

.review-swiper-button-next.swiper-button-disabled {
    opacity: .4;
}

.review-swiper-button-prev.swiper-button-disabled {
    opacity: .4;
}

@media (max-width: 1280px) and (min-width: 768px) {
    .feedbackSwiper {
        width: 60%;
        height: 50%;
    }
}


@media (max-width: 767px) and (min-width: 340px) {
    .feedbackSwiper {
        width: 100%;
        min-height: 45%;
    }
}