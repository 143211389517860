.container {
    max-width: 85%;
    margin: 0 auto;
}

.payment_title {
    margin: 30px auto;
    text-align: center;
    font-size: 40px;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    color: #fff;
    width: 50%;
}

.text {
    font-family: 'InterMedium', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    white-space: break-spaces;
    line-height: 26px;
    margin-bottom: 30px;
}

@media (min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media (max-width: 1280px) and (min-width: 768px) {
    .container {
        max-width: 90%;
    }

    .payment_title {
        font-size: 30px;
    }
}

@media (max-width: 767px) and (min-width: 340px) {
    .container {
        max-width: 95%;
    }

    .payment_title {
        width: 80%;
        font-size: 24px;
    }

}