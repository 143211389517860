.wrapper {
    background-color: #1C1D1F;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    width: 35%;
    min-height: 400px;
    background-color: #FFFFFF1A;
    border-radius: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    padding: 38px 48px;
    transition: 0.4s;
}

.title {
    font-size: 48px;
    font-weight: 600;
    font-family: 'PoppinsSemiBold', sans-serif;
    color: #fff;
    text-align: center;
    margin-bottom: 40px;
}

.new_pass_form {
    width: 100%;
}

.inputs {
    font-family: 'InterSemiBold', sans-serif;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    display: flex;
    width: 100%;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #3A3B3D;
    letter-spacing: 1px;
    border-radius: 16px;
    border: none;
    transition: 0.3s;
    margin-bottom: 20px;
}

.inputs:focus {
    outline: 1px solid #986BE2;
}

.continue_btn {
    width: 100%;
    height: 64px;
    margin: 20px 0 0;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    background-color: #986BE2;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    transition: 0.1s;
}

.continue_btn:active {
    background-color: transparent;
    border: 2px solid #986BE2;
}

.pass_text {
    width: 100%;
    display: block;
    color: #fff;
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
}

.error {
    color: rgb(255, 0, 0);
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 13px;
    display: block;
    width: 100%;
    margin: 10px 0;
    text-align: center;
}


@media(max-width: 1280px) {
    .container {
        width: 50%;
    }
}

@media(max-width: 992px) {
    .container {
        width: 60%;
    }

    .title {
        font-size: 35px;
    }
}

@media(max-width: 767px) {
    .container {
        width: 90%;
    }

    .title {
        font-size: 30px;
    }
}

@media(max-width: 480px) {
    .container {
        width: 95%;
        padding: 38px 10px;
    }
}