.none_title {
    margin: 70px auto 30px;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    font-size: 35px;
    line-height: 40px;
    letter-spacing: 1px;
    width: 50%;
    text-align: center;
}

.users_course_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.course_card {
    flex: 0 1 30%;
    border-radius: 20px;
    background-color: #2B2C2E;
    height: 448px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}

.course_card:hover {
    box-shadow: 0px 0px 30px 5px #986BE2;
}

.inset_card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}


.logo_block {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    margin-bottom: 27px;
    background-color: #3A3B3D;
    display: flex;
    border-radius: 24px;
}

.logo_block img {
    width: 80%;
    height: 80%;
    display: block;
    margin: 0 auto;
    align-self: center;
    object-fit: contain;
    object-position: center;
}

.title {
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    text-align: center;
    font-size: 32px;
    color: #fff;
    margin-bottom: 64px;
}

.btn_learn {
    width: 60%;
    padding: 16px 24px;
    background-color: #F1973D;
    text-decoration: none;
    text-align: center;
    border-radius: 16px;
}

.btn_learn:active {
    background-color: transparent;
    border: 1px solid #F1973D;
}

@media(max-width: 1200px) {
    .none_title {
        font-size: 26px;
    }
}

@media(max-width: 992px) {
    .course_card {
        flex: 0 1 48%;
    }
}

@media(max-width: 767px) {
    .none_title {
        font-size: 22px;
        line-height: 30px;
        width: 80%;
        margin: 20px auto 40px;
    }

    .btn_learn {
        width: 80%;
    }
}

@media(max-width: 470px) {
    .course_card {
        flex: 0 1 100%;
    }
}