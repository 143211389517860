.notFound_section {
    font-family: 'InterRegular', sans-serif;
    color: #FFF;
    font-size: 16px;

    height: 100vh;
    display: flex;
    align-items: center;

}

.container {
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.errorImg {
    width: 100%;
}

.notFound_title {
    font-family: 'PoppinsRegular';
    font-size: 32px;
    font-weight: 600;

}

.apology {
    color: #B1B1B5;
    font-weight: 500;
}

.toMainPage_btn {
    min-width: 50%;
    margin-top: 32px;
    padding: 16px 24px;
    border: none;
    border-radius: 16px;
    background-color: #986BE2;
    font-weight: 700;
    outline: none;

    cursor: pointer;
}

.toMainPage_btn:active {
    background-color: transparent;
    border: 1px solid #986BE2;
    color: #986BE2;
}

@media (min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media (max-width: 767px) and (min-width: 340px) {

    .container {
        max-width: 100%;
        padding: 0 16px;
    }


    .notFound_title {
        font-size: 28px;
    }

    .apology {
        font-weight: 400;
    }
}