.nav {
    flex: 0 1 40%;
    display: flex;
    /* overflow: hidden; */
    border: none;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-right: 1px solid #B1B1B5; */
    width: 100%;
}

.menu li {
    list-style: none;
    /* flex: 0 1 16%; */
    color: #B1B1B5;
    font-size: 16px;
    font-family: 'InterSemiBold', sans-serif;
    font-weight: 600;
}

.menu__items {
    display: block;
    width: 100%;
    font-family: 'InterSemiBold', sans-serif;
    font-weight: 600;
    color: #B1B1B5;
    text-decoration: none;
    font-size: 16px;

}

.active {
    color: #F1973D;
}

.lang_block {
    display: flex;
    gap: 10px;
}

.lang {
    color: #fff;
    cursor: pointer;
    font-family: 'InterSemiBold', sans-serif;
    font-weight: 600;
    color: #B1B1B5;
    text-decoration: none;
    font-size: 16px;
}

.active_lang {
    color: #a435d7;
}

@media(max-width: 1500px) {
    .nav {
        flex: 0 1 55%;
    }
}

@media(max-width: 1280px) {
    .nav {
        /* flex: 0 1 60%; */
    }
}

@media(max-width: 1040px) {
    .nav {
        display: none;
    }

    .menu__items {
        font-size: 14px;
    }
}