@font-face {
    font-family: 'InterLight', sans-serif;
    /* 300 */
    src: url('./assets/fonts/Inter-Light.ttf');
}

@font-face {
    font-family: 'InterRegular', sans-serif;
    /* 400 */
    src: url('./assets/fonts/Inter-Regular.ttf');
}

@font-face {
    font-family: 'InterMedium', sans-serif;
    /* 500 */
    src: url('./assets/fonts/Inter-Medium.ttf');
}

@font-face {
    font-family: 'InterSemiBold', sans-serif;
    /* 600 */
    src: url('./assets/fonts/Inter-SemiBold.ttf');
}

@font-face {
    font-family: 'InterBold', sans-serif;
    /* 700 */
    src: url('./assets/fonts/Inter-Bold.ttf');
}

@font-face {
    font-family: 'InterExtraBold', sans-serif;
    /* 800 */
    src: url('./assets/fonts/Inter-ExtraBold.ttf');
}

@font-face {
    font-family: 'InterBlack', sans-serif;
    /* 900 */
    src: url('./assets/fonts/Inter-Black.ttf');
}

@font-face {
    font-family: 'PoppinsThin', sans-serif;
    /* 100 */
    src: url('./assets/fonts/Poppins-Thin.ttf');
}

@font-face {
    font-family: 'PoppinsExLight', sans-serif;
    /* 200 */
    src: url('./assets/fonts/Poppins-ExtraLight.ttf');
}

@font-face {
    font-family: 'PoppinsLight', sans-serif;
    /* 300 */
    src: url('./assets/fonts/Poppins-Light.ttf');
}

@font-face {
    font-family: 'PoppinsRegular', sans-serif;
    /* 400 */
    src: url('./assets/fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'PoppinsMedium', sans-serif;
    /* 500 */
    src: url('./assets/fonts/Poppins-Medium.ttf');
}


@font-face {
    font-family: 'PoppinsSemiBold', sans-serif;
    /* 600 */
    src: url('./assets/fonts/Poppins-SemiBold.ttf');
}


@font-face {
    font-family: 'PoppinsBold', sans-serif;
    /* 700 */
    src: url('./assets/fonts/Poppins-Bold.ttf');
}


@font-face {
    font-family: 'PoppinsExtraBold', sans-serif;
    /* 800 */
    src: url('./assets/fonts/Poppins-ExtraBold.ttf');
}


@font-face {
    font-family: 'RubikMedium', sans-serif;
    /* 500 */
    src: url('./assets/fonts/Rubik-Medium.ttf');
}


.main {
    min-height: 90vh;
}

.chatbox_block {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 52px;
    z-index: 50;
}

.chatbox {
    width: 100%;
    cursor: pointer;
}

:where(.vi__container) {
    text-align: center !important;
    height: 70px !important;
    width: 500px !important;
}

:where(.vi__character) {
    color: #fff !important;
    background-color: #2B2C2E !important;
    border-radius: 8px !important;
}

:where(.vi__character--inactive) {
    color: rgba(55, 55, 55, 0.599) !important;
    background-color: #3A3B3D !important;
    border: none !important;
}

:where(.vi__character--selected) {
    outline: 2px solid #986BE2 !important;
    color: cornflowerblue;
}

:root {
    --orange: #F1973D;
    --grey: #B1B1B5;
    --violet: #986BE2;
    --light-gray: #F3F4F8;
}

.video-react .video-react-progress-holder .video-react-load-progress div {
    height: 10px !important;
}

.video-react .video-react-progress-holder {
    height: 10px !important;
    border-radius: 50px !important;
    /* overflow-y: hidden !important; */
}

.video-react .video-react-play-progress {
    background-color: var(--violet) !important;
    height: 10px !important;
    border-radius: 50px !important;
}

.video-react-play-progress:before {
    background-color: var(--violet) !important;
    color: var(--violet) !important;
    border-radius: 50%;
    font-size: 1.3em !important;
    top: -0.20em !important;
    overflow: hidden;
}

.video-react .video-react-slider:focus {
    box-shadow: 0 0 1em #a08ebd !important;
}

.video-react .video-react-progress-control:hover .video-react-play-progress:before {
    top: -0.30em !important;
}

.video-react-poster {
    background-size: cover !important;
}

@media(max-width: 590px) {
    :where(.vi__container) {
        height: 60px !important;
        width: 400px !important;
    }
}

@media(max-width: 430px) {
    :where(.vi__container) {
        height: 55px !important;
        width: 320px !important;
    }
}