.container {
    max-width: 85%;
    margin: 0 auto;
}

.title_block {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 64px 0;
}

.title_block img {
    cursor: pointer;
    border-radius: 50%;
    background-color: #5c5e626e;
    border: 1px solid #5c5e62;
}

.title_top {
    font-family: 'PoppinsSemiBold', sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #F3F4F8;
}

.wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
}

.empty {
    font-family: 'PoppinsSemiBold', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: var(--orange);
    text-align: center;
    width: 100%;
}

.card {
    flex: 0 1 30%;
    border-bottom: 1px solid #353945;
    margin-bottom: 64px;
}

.card:hover .preview {
    box-shadow: 0px 0px 25px 5px #986BE2;
}

.preview {
    width: 100%;
    height: 270px;
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
    margin-bottom: 32px;
    cursor: pointer;
    transition: 0.3s;
}

.title {
    font-family: 'PoppinsSemiBold', sans-serif;
    color: #FCFCFD;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 40px;
    height: 64px;
}

@media(max-width: 992px) {
    .wrapper {
        justify-content: space-between;
        gap: 0;
    }

    .card {
        flex: 0 1 48%;
    }

    .title_top {
        font-size: 26px;
    }
}

@media(max-width: 767px) {

    .card {
        flex: 0 1 100%;
    }

    .title {
        margin-bottom: 0px;
        font-size: 20px;
    }
}