.allCourses {
    font-family: 'InterRegular', sans-serif;
}

.container {
    max-width: 85%;
    margin: 0 auto;
}

.allCourses_title {
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    margin: 40px auto 0;
    /* max-width: 85%; */
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width: 490px) {
    .allCourses_title {
        text-align: center;
        text-shadow: 0px 1px 50px var(--violet);
    }
}