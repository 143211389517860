.wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    backdrop-filter: blur(10px);
}

.container {
    width: 30%;
    min-height: 500px;
    background-color: #3A3B3D;
    padding: 24px;
    border-radius: 24px;
}

.avatar {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin-bottom: 30px;
}

.mentor_name {
    /* margin: 30px 0 10px; */
    height: 24px;
    font-family: 'PoppinsSemiBold', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #F3F4F8;
    letter-spacing: 0.5px;
}

.work_place {
    margin-bottom: 5px;
    color: #F1973D;
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    height: 24px;
}

@media(max-width: 1024px) {
    .container {
        width: 50%;
    }
}

@media(max-width: 767px) {
    .container {
        width: 85%;
    }
}