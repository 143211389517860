.faq_card {
    border-top: 1px solid #3A3B3D;
    border-bottom: 1px solid #3A3B3D;
}


.question {
    padding: 32px 8px;
    color: #F3F4F8;
    font-family: 'PoppinsRegular', sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.answer_collapse {
    height: 0;
    overflow: hidden;
    transition: height 0.4s ease-in-out;
}

.answer_collapse.open {
    height: auto;
}

.answer_collapse.closed {
    height: 0;
}

.arrow_down,
.arrow_up,
.close_question {
    object-fit: contain;
    object-position: center;
    cursor: pointer;
}

.arrow_up {
    transform: rotate(180deg);
    transition: 0.4s;
}

.close_question {
    transform: rotate(360deg);
    transition: 0.4s;
}

.answer {
    color: #B1B1B5;
    padding: 12px 4px;
    overflow: hidden;
    max-height: 0;
}

.answerOpen {
    max-width: 95%;
    margin: 0 auto;
    color: #B1B1B5;
    padding: 12px 4px;
    overflow: auto;
    max-height: 100%;
}