.container {
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile_block {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
    flex: 0 1 40%;
    user-select: none;
}

.avatar_img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    box-shadow: 0px 0px 25px 1px #986BE2;
}


.text_block h1 {
    font-family: 'InterSemiBold', sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #F3F4F8;
    line-height: 44px;
}

.text_block p {
    font-family: 'InterMedium', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #76797D;
    line-height: 22px;
}

.btn_block {
    flex: 0 1 20%;
}

.add_btn {
    /* flex: 0 1 20%; */
    width: 100%;
    padding: 16px 24px;
    border-radius: 16px;
    background-color: #986BE2;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    align-self: center;
    border: none;
    cursor: pointer;
}

.add_btn:active {
    background-color: transparent;
    border: 1px solid #986BE2;
    color: #986BE2;
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width: 1100px) {
    .profile_block {
        flex: 0 1 50%;
    }
}

@media(max-width: 900px) {
    .text_block h1 {
        font-size: 26px;
    }

    .text_block p {
        font-size: 14px;
    }
}

@media(max-width: 767px) {
    .container {
        flex-direction: column;
    }

    .profile_block {
        margin-bottom: 40px;
    }

    .container a {
        width: 100%;
    }
}

@media(max-width: 430px) {
    .container {
        max-width: 95%;
    }

    .text_block h1 {
        font-size: 22px;
    }
}