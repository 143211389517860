.burgerMenu {
    width: 50px;
    height: 50px;
}

.menu_btn_active,
.menu_btn {
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 50;
    cursor: pointer;
    background: hsla(0, 0%, 0%, 0.5);
    border-radius: 10px;
    right: 10px;
    top: 20px;
}


.menu_btn_active span,
.menu_btn span {
    width: 30px;
    height: 2px;
    position: absolute;
    background-color: #986BE2;
    transition: all 0.5s;
    z-index: 50;
    left: 20%;
    top: 25%;
}

.menu_btn span {}


.menu_btn span:nth-of-type(2) {
    top: calc(55% - 5px);
}

.menu_btn span:nth-of-type(3) {
    top: calc(55% + 5px);
}

.menu_btn_active span:nth-of-type(1) {
    display: none;
}

.menu_btn_active span:nth-of-type(2) {
    top: 45%;
    transform: rotate(45deg);
}

.menu_btn_active span:nth-of-type(3) {
    top: 45%;
    transform: rotate(-45deg);
}


.menu_active,
.menu {
    position: fixed;
    width: 100%;
    top: 0px;
    right: 0px;
    height: 100vh;
    background: #1C1D1F;
    transition: 0.5s;
    z-index: 49;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 5%;
    text-align: left;
}

.menu_active {
    transform: translateX(0);

}

.menu {

    transform: translateX(100%);

}

.list {
    list-style-type: none;
    list-style: none;
    margin-bottom: 32px;
}

.links,
.menu_item {
    font-family: 'PoppinsSemiBold', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #B1B1B5;
}

.activeMenu_item {
    font-family: 'PoppinsSemiBold', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #F1973D;
    order: 4;
    flex-grow: 0;

}

.navigation {
    margin-top: 90px;

}

.navigation ul li {
    line-height: 32px;
    padding-left: 30px;
}

.connect_btn {
    width: 258px;
    height: 44px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #FFCD00;
    margin-bottom: 20%;
}

.box_contact_info {
    display: flex;
    justify-content: space-between;
    width: 40%;
}

.box_contact_info a {
    flex: 0 1 20%;
    cursor: pointer;
}

.box_contact_info a img {
    width: 100%;
}

.lang_block {
    display: flex;
    gap: 10px;
    padding-left: 30px;
    margin: 30px 0;
}

.lang {
    color: #fff;
    cursor: pointer;
    font-family: 'InterSemiBold', sans-serif;
    font-weight: 600;
    color: #B1B1B5;
    text-decoration: none;
    font-size: 26px;
}

.active_lang {
    color: #a435d7;
}

@media (min-width:1040px) {
    .burgerMenu {
        display: none;
    }
}