.skill_block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border: none;
    gap: 1%;
    height: 300px;
}

.skill_item {
    flex: 1 1 10%;
    border-radius: 10px;
    padding: 0px 12px;
    margin: 0px 20px;
    list-style: disc;
}