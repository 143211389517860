.bootcamp_section {
    min-height: 100vh;
    display: flex;
    align-items: center;
    font-family: 'PoppinsRegular', sans-serif;
    overflow: hidden;
}

.container {
    max-width: 85%;
    margin: 0 auto;
}

.bg {
    background: #2B2C2E;
    border: none;
    border-radius: 24px;

    position: relative;
    z-index: 1;
}

.bg::before {
    content: '';
    position: absolute;
    left: 5%;
    top: 10%;
    width: 30%;
    height: 60%;
    background: linear-gradient(135deg, #AE80DC 0%, #DC83C3 47.77%, #8084DC 100%);
    border-radius: 50%;
    filter: blur(50px);
    z-index: -10;
    animation: gradient 5s linear infinite;
}

@keyframes gradient {

    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg) scale(1.3);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

.dark_bg {
    border-radius: 24px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.715);
    z-index: 3;
}

.card {
    border: none;
    border-radius: 24px;
    margin: 100px 0;
    padding: 100px 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftSide {
    z-index: 4;
    flex: 0 1 45%;
}

.section_title {
    color: var(--orange);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.title_description {
    color: #F3F4F8;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 24px;
}

.description {
    color: var(--grey);
    font-family: 'InterRegular', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.rightSide {
    z-index: 4;
    flex: 0 1 45%;
    position: relative;
}

.rightSide img {
    transform: scale(1.2);
    width: 100%;
    position: inherit;
    top: -60px;
    left: 120px;
}

@media (min-width: 1921px) {

    .container {
        max-width: 1440px;
    }

}

@media (max-width: 1280px) and (min-width: 767px) {

    .bg::before {
        width: 35%;
        height: 50%;
        filter: blur(30px);
    }

    .card {
        margin: 10px 0;
        padding: 100px 70px;
    }

    .rightSide img {
        position: relative;
        object-fit: cover;
        transform: scale(1.5);
        top: -50px;
        left: 30px;
    }
}

@media (max-width: 767px) and (min-width: 340px) {
    .container {
        max-width: 100%;
    }

    .bg::before {
        top: 0;
        width: 60%;
        height: 80%;
        filter: blur(60px);
    }

    .card {
        padding: 48px 15px;
        flex-direction: column-reverse;
    }

    .section_title {
        font-size: 14px;
        letter-spacing: 0.7px;
        margin-bottom: 12px;
    }

    .title_description {
        font-size: 24px;
        line-height: 36px;
    }

    .description {
        font-weight: 400;
    }

    .rightSide img {
        width: 100%;
        top: -50px;
        left: -20px;
    }
}