.course_section {
    padding-bottom: 100px;
}

.container {
    max-width: 85%;
    margin: 0 auto;
}

.directionName {
    color: #FFF;
    font-family: 'InterRegular', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0.96px;
    margin: 64px 0 24px;
}

.course_card {
    background: #2B2C2E;
    padding: 50px;
    border: none;
    border-radius: 20px;

}

.course_direction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #3A3B3D;
    margin-bottom: 32px;
    padding-bottom: 32px;
}

.icon_box {
    width: 122px;
    height: 122px;
    background-color: #3A3B3D;
    border-radius: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

}

.icon {
    width: 80%;
    height: 80%;
    object-fit: contain;
    object-position: center;
    border-radius: 24px;

}

.course_dir_detail {
    flex: 0 1 60%;
}

.course_dir_detail h3 {
    color: #FFF;
    font-family: 'InterRegular', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

.course_dir_actions {
    flex: 0 1 23%;
}

.detailBtn {
    font-size: 16px;
    font-weight: 700;
    background: transparent;
    padding: 16px 24px;
    border-radius: 16px;
    border: 2px solid #fff;
    border-radius: 16px;
    margin-top: 20px;
    min-width: 40%;
    cursor: pointer;
}

.detailBtn:active {
    border: 1px solid #986BE2;
    color: #986BE2;

}

.course_dir_actions {
    display: flex;
    flex-direction: column;
}

.bookCourse,
.purchaseCourse {
    background: transparent;
    border: none;
    margin: 8px;
}

.bookCourse {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 16px 24px;
    background-color: #986BE2;
    border-radius: 16px;
    width: 100%;
    cursor: pointer;
}

.bookCourse:active {
    background: transparent;
    border: 1px solid #986BE2;
    color: #986BE2;
}

.purchaseCourse {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 16px 24px;
    border-radius: 16px;
    border: 2px solid #fff;
    width: 100%;
    cursor: pointer;
    justify-self: flex-end;
    margin-top: 15px;
    margin-bottom: 0px;
}

.purchaseCourse:active {
    background: #986BE2;
    color: #fff;
}

.course_technologies {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'InterRegular', sans-serif;
}

.technologies_detail {
    flex: 0 1 60%;
}

.technologies_detail h3 {
    color: #FFF;
    font-family: 'InterRegular', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

.course_tech_actions {

    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 0 1 23%;
    align-self: flex-end;
}

.link_booking {
    display: block;
    width: 100%;
}

.price_block {
    color: #B1B1B5;
    font-family: 'InterMedium', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0;
    text-align: center;
}

.price {
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.clue {
    color: #B1B1B5;
    font-family: 'InterMedium', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin: 15px 0 0;
}

.already_have {
    color: #986BE2;
    text-align: center;
    margin: 10px 0 10px 8px;
    padding: 16px 5px;
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width: 1040px) {

    .course_dir_detail,
    .technologies_detail {
        flex: 0 1 55%;
    }
}

@media(max-width: 992px) {

    .course_dir_detail,
    .technologies_detail {
        flex: 0 1 50%;
        padding-left: 10px;
    }
}

@media(max-width: 767px) {

    .course_direction,
    .course_technologies {
        flex-direction: column;
        text-align: center;
    }

    .course_dir_detail,
    .technologies_detail {
        flex: 0 1 100%;
        width: 100%;
        padding: 0;
    }

    .course_dir_detail h3,
    .technologies_detail h3 {
        margin: 15px 0;
    }

    .detailBtn {
        width: 100%;
        margin: 0;
    }

    .course_tech_actions {
        /* flex: 0 1 100%; */
        width: 100%;
    }

    .bookCourse {
        margin: 8px 0;
    }

    .purchaseCourse {
        margin: 15px 0;
        width: 100%;
        align-self: flex-end;
    }

    .course_dir_actions {
        margin-top: 15px;
        width: 100%;
    }

    .clue {
        margin-bottom: 15px;
    }

}

@media(max-width: 490px) {
    .container {
        max-width: 95%;
    }

    .course_card {
        padding: 20px 15px;
    }


}