.skills_section {
    background: #141416;
    font-family: 'InterRegual', sans-serif;
    font-weight: 400;
    min-height: 100vh;
    display: flex;
    align-items: center;

}

.container {
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    text-align: center;
    color: #F3F4F8;
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    margin-bottom: 20px;
}

.description {
    color: #B1B1B5;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 72px;
}

.technologies {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.link_item {
    flex: 0 1 20%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 2%;
    text-decoration: none;
    background-color: #2B2C2E;
    padding: 15px;
    border-radius: 23px;
    transition: 0.3s;
}

.link_item:hover {
    box-shadow: 0px 0px 25px 5px #986BE2;
}

.imgBlock {
    display: flex;
    justify-content: center;
    width: 50%;
    height: 50%;
    margin-bottom: 24px;
}

.technologyLogo {
    width: 80%;
    object-fit: contain;
    object-position: center;
}

.technologyName {
    color: #F3F4F8;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25.3px;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.technologyDescription {
    color: #B1B1B5;
    text-align: center;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.36px;
    height: 60px;
    /* -webkit-line-clamp: 2; */
    /* display: -webkit-box; */
    /* -webkit-box-orient: vertical; */
    /* overflow: hidden; */
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width: 1087px) {
    .link_item {
        flex: 0 1 23%;
    }
}

@media(max-width: 992px) {
    .link_item {
        height: 300px;
        flex: 0 1 45%;
        margin-bottom: 40px;
        box-shadow: 0px 0px 15px 0px #986BE2;
        animation: links 1.2s ease infinite alternate;
        transition: all 0.5s;
    }

    @keyframes links {
        0% {
            box-shadow: 0px 0px 5px 0px #986BE2;
        }

        /* 50% {
            box-shadow: 0px 0px 20px 2px #986BE2;
        } */

        100% {
            box-shadow: 0px 0px 25px 3px #986BE2;
        }
    }

    .technologyDescription {
        overflow: visible;
    }
}

@media(max-width: 767px) {
    .container {
        max-width: 95%;
    }

    .technologyDescription {
        overflow: visible;
    }
}

@media(max-width: 600px) {
    .link_item {
        flex: 0 1 100%;
    }

    .title {
        font-size: 30px;
    }
}