.resume_section {
    font-family: 'InterRegular', sans-serif;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    flex: 0 1 100%;
    max-width: 85%;
    margin: 0 auto;
    padding: 70px 0;
}

.resume_title {
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    max-width: 50%;
}

.resume_card {
    background-color: #3A3B3D;
    padding: 48px 42px 46px;
    margin: 32px 0;
    border-radius: 20px;
}

.resume_top {
    color: #F3F4F8;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.leftSide {
    display: flex;
    align-items: center;
    gap: 24px;
}

.resume_main>* {
    margin-top: 48px;
}

.stack_info,
.skills_info,
.projects_info {
    display: flex;
    justify-content: space-between;
}

.stack_info_title,
.skills_info_title,
.projects_info_title {
    color: #F3F4F8;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.stack_info_list,
.skills_list,
.projects_list {
    flex: 0 1 70%;
    display: flex;
    flex-wrap: wrap;
    color: #F3F4F8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

@media (min-width: 1921px) {
    .container {
        flex: 0 1 100%;
        max-width: 1440px;
        margin: 0 auto;
    }

    .resume_title {
        max-width: 100%;
    }
}

@media (max-width: 767px) and (min-width: 340px) {
    .container {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 70px 16px;
    }

    .resume_title {
        max-width: 100%;
        font-size: 24px;
    }

    .resume_card {
        padding: 24px;
    }


    .resume_top {
        font-size: 18px;
        line-height: 24px;
    }

    .stack_info,
    .skills_info,
    .projects_info {
        display: block;
    }

    .stack_info_title,
    .skills_info_title,
    .projects_info_title {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .stack_info_list,
    .skills_list,
    .projects_list {
        flex: 0 1 70%;
        display: block;
        font-size: 14px;
        line-height: 14px;
    }
}