.hero_section {
    min-height: 100vh;
    position: relative;
    font-family: 'InterRegular', sans-serif;
    z-index: 0;
}

.hero_section::before {
    content: '';
    position: absolute;
    left: 5%;
    top: 10%;
    width: 700px;
    height: 650px;
    background: linear-gradient(135deg, #AE80DC 0%, #DC83C3 47.77%, #8084DC 100%);
    border-radius: 50%;
    filter: blur(50px);
    z-index: 1;
    animation: gradient 5s linear infinite;
}

@keyframes gradient {

    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg) scale(1.2);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

.dark_bg {
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.container {
    max-width: 85%;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    z-index: 3;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.block {
    flex: 0 1 45%;
}

.main_title {
    z-index: 1;
    font-size: 72px;
    line-height: 80px;
    font-family: 'PoppinsRegular', sans-serif;
    font-weight: 700;
}

.title_description {
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    color: var(--grey);
    margin: 20px 0 30px;
    max-width: 80%;
}

.main_section_buttons {
    display: flex;
    justify-content: space-between;
}


.leaveAReq_btn {
    background-color: var(--violet);
    color: white;
    font-size: 16px;
    font-weight: 700;
    padding: 16px 30px;
    text-align: center;
    border: none;
    border-radius: 20px;
    flex: 0 1 40%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leaveAReq_btn:active {
    background-color: transparent;
    border: 1px solid #986BE2;
    color: #986BE2;
}

.whatchAPresentation {
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 16px 30px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 20px;
    flex: 0 1 50%;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.whatchAPresentation:active {
    background-color: transparent;
    border: 1px solid #986BE2;
    color: #986BE2;
}

.hero_icon {
    width: 600px;
    height: 600px;
    object-fit: contain;
    object-position: center;
    display: block;
    margin: 0 auto;
}

@media (min-width: 1921px) {

    .container {
        max-width: 1440px;
    }
}

@media (min-width: 768px) and (max-width: 1280px) {

    .hero_section {
        overflow: hidden;
    }

    .hero_section::before {
        left: 2%;
        top: 5%;
        width: 600px;
        height: 600px;
    }

    .rightSide {
        flex: 0 1 55%;
    }

    .main_title {
        font-size: 48px;
        line-height: 54px;
    }

    .title_description {
        font-size: 16px;
        line-height: 22px;
        max-width: 90%;
    }

    .main_section_buttons {
        flex-direction: column;
    }


    .leaveAReq_btn {
        margin-bottom: 16px;
    }

    .hero_icon {
        width: 400px;
        height: 400px;
    }

}


@media (max-width: 767px) and (min-width:340px) {

    .hero_section {
        overflow: hidden;
    }

    .hero_section::before {
        top: 0;
        left: 0;
        width: 500px;
        height: 100px;
        padding-bottom: 60%;
    }

    .container {
        padding: 0 32px;
        max-width: 100%;
        height: auto;
        flex-direction: column-reverse;
    }

    .block {
        /* width: 90%; */
        flex: 0 1 45%;
        margin: 20px 0;
    }

    .main_title {
        z-index: 1;
        font-size: 40px;
        line-height: 54px;
        font-weight: 600;
    }

    .title_description {
        font-size: 16px;
        margin: 12px 0 24px;
        max-width: 90%;
    }

    .main_section_buttons {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .leaveAReq_btn,
    .whatchAPresentation {
        width: 100%;
        padding: 12px 24px;
        text-align: center;
    }

    .hero_icon {
        width: 300px;
        height: 300px;
    }
}