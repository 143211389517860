.stydyingProcess_section {
    font-family: 'InterRegular', sans-serif;
    position: relative;
    z-index: 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.stydyingProcess_section::before {
    content: '';
    position: absolute;
    left: 0%;
    top: 5%;
    width: 35%;
    height: 70%;
    background: linear-gradient(135deg, #AE80DC 0%, #DC83C3 47.77%, #8084DC 100%);
    border-radius: 50%;
    filter: blur(50px);
    z-index: -10;
    animation: gradient 5s linear infinite;
}

@keyframes gradient {

    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg) scale(1.2);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

.dark_bg {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.80);
}

.container {
    position: relative;
    z-index: 3;
    max-width: 85%;
    margin: 0 auto;
}

.title_des {
    font-size: 40px;
    font-family: 'PoppinsRegular', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
}

.description {
    color: #B1B1B5;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 20px;
}

.education_processes {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 40px;

    position: relative;
}

.process {
    flex: 0 1 25%;
    height: 300px;
    text-align: center;
    justify-content: start;
    position: relative;

}

.num {
    padding: 28px;
    border: none;
    border-radius: 24px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    max-width: 40%;
    margin: 0 auto;
    margin-bottom: 50px;

}

.first_process {
    background: #ED5158;
}

.second_process {
    background-color: #75B5FB;
}

.third_process {
    background-color: #986BE2;
}

.fourth_process {
    border-radius: 24px;
    background: #F1973D;
}

.process p {
    color: #F3F4F8;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;

    height: 50px;
}

.process span {
    color: #B1B1B5;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}


#line1 {
    position: absolute;
    top: 20%;
    left: 15%;
    width: 30%;
    height: auto;
}

#line2 {
    position: absolute;
    top: 20%;
    left: 41%;
    width: 30%;
    height: auto;
}

#line3 {
    position: absolute;
    top: 20%;
    right: 3%;
    width: 30%;
    height: auto;
}


.connectLine {
    width: 100%;
    object-fit: contain;
}


@media (min-width: 1921px) {

    .container {
        max-width: 1440px;
    }

}

@media (max-width: 1280px) and (min-width: 768px) {
    .stydyingProcess_section::before {
        width: 60%;
    }

    .container {
        padding-top: 70px;
    }

    .education_processes {
        padding: 50px 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 40px;
    }

    .process {
        flex: 0 1 35%;
    }


    .num {
        width: 80px;
        height: 80px;
    }

    .lineBlock {
        display: none;
    }

}

@media (max-width: 767px) and (min-width: 340px) {
    .stydyingProcess_section {
        overflow: hidden;
    }

    .stydyingProcess_section::before {
        left: 0;
        top: 20%;
        width: 120%;
        height: 60%;
    }

    .container {
        max-width: 100%;
        padding: 70px 16px 0;

    }

    .title_des {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 8px;
    }

    .description {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .education_processes {
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 40px;
    }

    .num {
        width: 80px;
        height: 80px;
    }

    .process {
        margin: 32px 0;
    }

    .process p {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .process span {
        font-size: 14px;
    }


    #line1 {
        position: absolute;
        top: 26%;
        left: 25%;
        width: 50%;
        height: auto;
    }

    #line2 {
        position: absolute;
        top: 50%;
        left: 25%;
        width: 50%;
        height: auto;
    }

    #line3 {
        position: absolute;
        top: 73%;
        left: 25%;
        width: 50%;
        height: auto;
    }
}