.footer {
    font-family: 'PoppinsRegular', sans-serif;
    color: var(--light-gray);
    font-size: 20px;
    padding: 78px 0 89px;
    display: flex;
    align-items: center;
    background-color: #2B2C2E;
}

.container {
    flex: 0 1 100%;
    max-width: 85%;
    margin: 0 auto;
}

.footer_top_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
    background-color: #2B2C2E;
}

.logo_block {
    text-align: center;
}

.logo {
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.footer_nav {
    flex: 0 1 60%;
    display: flex;
    justify-content: flex-end;
    gap: 5%;
    letter-spacing: 0.8px;
}

.footer_bottom_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: var(--grey);
}

.social_media {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon_link {
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.cards_imgs {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.cards_imgs img {
    width: 80px;
    height: 60px;
}

.elcart {
    height: 40px !important;
}

@media (min-width: 1921px) {

    .container {
        max-width: 1440px;
    }

}

@media(max-width: 1240px) {
    .footer_nav {
        flex: 0 1 70%;
    }
}

@media (max-width: 767px) and (min-width: 340px) {
    .container {
        max-width: 100%;
    }

    .footer_top_block {
        flex-direction: column;
    }

    .footer_nav {
        margin-top: 20px;
        flex: 0 1 100%;
        flex-direction: column;
        justify-content: center;
        letter-spacing: 0.8px;
    }

    .links {
        text-align: center;
        margin: 20px 0;
    }

    .footer_bottom_block {
        flex-direction: column-reverse;
        gap: 40px;
    }

    .devs_info {
        text-align: center;
    }

}