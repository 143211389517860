.privacy_policy {
    min-height: 100vh;
    font-family: 'PoppinsRegular', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: var(--light-gray);

}

.container {
    flex: 0 1 100%;
    max-width: 70%;
    margin: 0 auto;
}

.privacy_title {
    margin: 40px 0;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

.text {
    white-space: break-spaces;
    color: var(--light-gray);
    line-height: 30px;
    margin-bottom: 30px;
    font-family: 'PoppinsRegular', sans-serif;
    font-weight: 400;
    font-size: 16px;
}

@media (min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media (max-width: 1280px) and (min-width: 768px) {
    .container {
        max-width: 90%;
    }
}

@media (max-width: 767px) and (min-width: 340px) {
    .container {
        max-width: 95%;
    }

    .privacy_title {
        font-size: 26px;
    }

}