.technologies_section {
    background: #141416;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'PoppinsRegular', sans-serif;
}

.container {
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 100%;
}

.section_title {
    text-transform: uppercase;
    color: #f1973d;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.8px;
    margin-bottom: 16px;
}

.section_title_des {
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 24px;

}

.description {
    color: #6E757C;
    font-family: 'InterRegular';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.rightSide {
    display: flex;
    align-items: center;
}

.icon_block {
    background-color: #2B2C2E;
    padding: 32px;
    border-radius: 24px;
    margin: 20px;
}

.icon {
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.figmaIcon {
    padding-left: 10px;
}

@media (min-width: 1921px) {

    .container {
        max-width: 1440px;
    }

}

@media (max-width: 1280px) and (min-width: 768px) {
    .icon_block {
        width: 128px;
        height: 128px;
        margin: 10px;
    }
}

@media (max-width: 767px) and (min-width: 340px) {
    .technologies_section {
        padding: 70px 16px;
    }

    .container {
        max-width: 100%;
        flex-direction: column-reverse;
    }

    .section_title {
        font-size: 14px;
        margin-bottom: 12px;
        letter-spacing: 0.7px;
    }

    .section_title_des {
        margin-bottom: 20px;
        color: #F3F4F8;
        font-size: 24px;
        line-height: 36px;
    }

    .rightSide {
        gap: 0;
        margin-bottom: 70px;

    }

    .column2 {
        margin: 0 10px;
    }

    .column1,
    .column2,
    .column3 {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .icon_block {
        width: 95px;
        height: 95px;
        margin: 0;
    }

}