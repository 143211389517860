.card {
    flex: 0 1 30%;
    border-bottom: 1px solid #353945;
    margin-bottom: 64px;
}

.card:hover .preview {
    box-shadow: 0px 0px 25px 5px #986BE2;
}

.preview {
    width: 100%;
    height: 270px;
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
    margin-bottom: 32px;
    cursor: pointer;
    transition: 0.3s;
}

.title {
    font-family: 'PoppinsSemiBold', sans-serif;
    color: #FCFCFD;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 40px;
    height: 64px;
}

@media(max-width: 992px) {
    .card {
        flex: 0 1 48%;
    }
}

@media(max-width: 767px) {
    .card {
        flex: 0 1 100%;
    }

    .title {
        margin-bottom: 0px;
        font-size: 20px;
    }
}