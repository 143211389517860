.faq_section {
    background: #141416;
    width: 100%;
    min-height: 100vh;

    background: #141416;
}

.container {
    max-width: 85%;
    margin: 0 auto;
    padding: 100px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section_title {
    color: #F1973D;
    font-size: 16px;
    font-family: 'PoppinsRegular', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 12px;
}

.title_des {
    color: #F3F4F8;
    font-size: 40px;
    font-family: 'PoppinsRegular', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.description {
    color: #B1B1B5;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    display: block;
    margin-bottom: 64px 0;
}

.faq_card {
    padding: 32px 0;
}