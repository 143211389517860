.feedback_section {
    height: 100vh;
    font-family: 'PoppinsRegular', sans-serif;
}

.container {
    background: url(../../assets/images/HomePage/profilePic1.png), url(../../assets/images/HomePage/avatar.png), url(../../assets/images/HomePage/profilePic2.png), url(../../assets/images/HomePage/profilePic3.png), url(../../assets/images/HomePage/profilePic4.png), url(../../assets/images/HomePage/profilePic5.png);
    background-repeat: no-repeat;
    background-position: 10% 10%, 0 50%, 10% 90%, 90% 10%, 100% 50%, 90% 90%;


    max-width: 85%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section_title {
    color: var(--orange);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    text-align: center;
}

.title_description {
    color: #F3F4F8;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
    margin-bottom: 48px;
    max-width: 40%;
}


.feedback_card {
    background-color: #2B2C2E;
    padding: 48px 40px;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.quoteMark {
    align-self: flex-start;
    justify-self: flex-start;
}

.feedback_content {
    color: #B1B1B5;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 30px;
    flex: 1 0 50%;
    display: flex;
    align-items: center;
    padding: 12px 0;

}

.feedback_author {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2%;
    width: 100%;
}

.authors_name {
    color: var(--grey);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
}

.speciality {
    color: var(--orange);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}


.arrows {
    margin: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.arrows button {
    background-color: transparent;
    border: none;
    cursor: pointer;

}

.arrowDisabled {
    transform: scale(0.8);
    filter: grayscale(60%);
}

.leaveAfeedback_btn {
    margin-top: 70px;
    color: #FFF;
    font-family: 'InterRegular', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    padding: 16px 24px;
    background-color: transparent;
    border: 1px solid #986BE2;
    border-radius: 16px;
    width: 20%;
    cursor: pointer;

    transition: 0.3s;
}

.leaveAfeedback_btn:active {
    background-color: transparent;
    border: 1 solid #986BE2;
    color: #986BE2;
}

.avatar {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

@media (min-width: 1921px) {

    .container {
        max-width: 1440px;
    }

}

@media (max-width: 1280px) and (min-width: 768px) {
    .container {
        background: url(../../assets/images/HomePage/profilePic1.png), url(../../assets/images/HomePage/avatar.png), url(../../assets/images/HomePage/profilePic2.png), url(../../assets/images/HomePage/profilePic3.png), url(../../assets/images/HomePage/profilePic4.png), url(../../assets/images/HomePage/profilePic5.png);
        background-repeat: no-repeat;
        background-position: 5% 5%, 0 50%, 5% 95%, 95% 5%, 100% 50%, 95% 95%;


        max-width: 85%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .leaveAfeedback_btn {
        width: 35%;
    }
}

@media (max-width: 767px) and (min-width: 340px) {
    .feedback_section {
        max-height: 90vh;
        padding: 0 16px;
    }

    .container {
        background: none;
        max-width: 100%;
    }

    .section_title {
        font-size: 14px;
        letter-spacing: 0.7px;
    }

    .title_description {
        font-size: 24px;
        line-height: 36px;
        margin: 8px 0 40px;
        max-width: 100%;
    }


    .feedback_card {
        padding: 27px 16px;
    }

    .feedback_content {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 26px;
    }

    .authors_name {
        font-size: 16px;
        line-height: normal;
        letter-spacing: 0.8px;
    }

    .speciality {
        font-size: 14px;
        line-height: normal;
    }


    .arrows {
        margin: 30px;
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    .leaveAfeedback_btn {
        margin-top: 84px;
        width: 80%;
    }
}