.label_choose {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px;
    gap: 20px;
    letter-spacing: 0.96px;
    user-select: none;
    cursor: pointer;
    background-color: #3A3B3D;
    border-radius: 6px;
    margin-bottom: 20px;
}

.when {
    font-family: 'InterBold', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    line-height: 28px;
}

.when::first-letter {
    text-transform: uppercase;
}

.info_block {
    margin-left: 40px;
}

.mentor_block {
    color: #B1B1B5;
    font-family: 'InterMedium', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.mentor_name {
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.bottom_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}