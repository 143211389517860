.sertificate_section {
    position: relative;
    z-index: 0;
    overflow: hidden;
    min-height: 100vh;
    display: inline-flex;
    width: 100%;
    align-items: center;
}


.sertificate_section::before {
    content: '';
    position: absolute;
    left: 5%;
    bottom: 0%;
    width: 40%;
    height: 80%;
    background: linear-gradient(135deg, #AE80DC 0%, #DC83C3 47.77%, #8084DC 100%);
    mix-blend-mode: overlay;
    border-radius: 50%;
    filter: blur(50px);
    z-index: -10;
    animation: gradient 7s linear infinite;
}

@keyframes gradient {

    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg) scale(1.1);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

.darker_bg {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);

}

.container {
    position: relative;
    z-index: 3;
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftSide,
.rightSide {
    flex: 0 1 45%;
}


.section_title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #F1973D;
    margin-bottom: 16px;
}

.title_description {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    color: #FFF;
    margin-bottom: 12px;
    max-width: 80%;
}

.description {
    font-family: 'InterRegular', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #B1B1B5;
    max-width: 70%;
}

.rightSide {
    background-color: #3A3B3D;
    border: none;
    border-radius: 23px;
    padding: 44px 54px;
}

.certificate_sample_img {
    width: 100%;
}

@media (min-width: 1921px) {

    .container {
        max-width: 1440px;
    }

}

@media (max-width: 1280px) and (min-width: 768px) {

    .sertificate_section::before {
        left: 0%;
        bottom: 10%;
        width: 60%;
        height: 80%;
    }

    .leftSide {
        flex: 0 1 45%;
    }

    .rightSide {
        flex: 0 1 50%;
    }

    .title_description {
        font-size: 42px;
    }

    .description {
        font-size: 16px;
    }

    .rightSide {
        padding: 22px 34px;
    }

    .certificate_sample_img {
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}

@media (max-width: 767px) and (min-width: 340px) {

    .container {
        padding: 70px 16px;
        max-width: 100%;
        flex-direction: column;
    }

    .section_title {
        font-size: 14px;
        letter-spacing: 0.7px;
    }

    .title_description {
        max-width: 100%;
        font-size: 24px;
        line-height: 36px;
    }

    .description {
        max-width: 100%;
        font-size: 16px;
        font-weight: 400;
    }

    .rightSide {
        margin-top: 47px;
        padding: 20px;
    }
}