.projects_block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 300px;
    gap: 1%;
}

.project_item {
    margin: 6px 22px;
}