.settings_wrapper {
    position: absolute;
    left: 0;
    bottom: -300%;
    z-index: 97;
    width: 100%;
    height: 180px;
    background-color: #2B2C2E;
    border-radius: 16px;
    box-shadow: 10px 15px 50px 0px rgba(0, 0, 0, 0.20);
    padding: 20px 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.choice_block {
    display: flex;
    align-items: center;
    gap: 20px;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s;
    padding: 12px;
    font-size: 14px;
}

.choice_block:hover {
    border-radius: 8px;
    background-color: #3A3B3D;
    opacity: 1;
}

.choice_block h4 {
    font-family: 'RubikMedium', sans-serif;
    font-weight: 500;
}

.choice_block:last-child h4 {
    color: #E15B5D;
}

.acc_img {}

.acc_title {}

@media(max-width: 551px) {
    .settings_wrapper {
        width: 120%;
    }
}