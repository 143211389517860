.container {
    max-width: 65%;
    margin: 50px auto;
    text-align: center;
}

.title_block {
    font-family: 'PoppinsSemiBold', sans-serif;
}

.section_title {
    color: var(--orange);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.title_description {
    color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 24px;
}

.mentors_info {
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #B1B1B5;
    width: 40%;
    margin: 0 auto;
}

.all_mentors_block {
    margin: 30px 0;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

@media(max-width: 992px) {
    .title_description {
        font-size: 30px;
    }

    .mentors_info {
        width: 70%;
    }

    .all_mentors_block {
        gap: 10px;
    }
}

@media(max-width: 767px) {
    .container {
        max-width: 95%;
    }

    .title_description {
        font-size: 26px;
        line-height: 35px;
    }

    .mentors_info {
        width: 70%;
        font-size: 16px;
        line-height: 24px;
    }

    .all_mentors_block {
        justify-content: space-between;
    }
}