a {
    text-decoration: none;
}

.contacts {
    min-height: 100vh;
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 20px;
    color: var(--light-gray);
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.contacts a,
.contacts span {
    line-height: 28px;
}

.container {
    flex: 0 1 100%;
    max-width: 50%;
    margin: 0 auto;
}

.contact_title {
    margin: 40px 0;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

.email {
    display: flex;
    align-items: center;
    gap: 10px;
}

.social_item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.social_item {
    object-fit: cover;
    object-position: center;
}

.physical_address {
    padding: 16px 0;
}

.physical_address p {
    margin-bottom: 10px;
}

.telephone_number {
    padding: 16px 0;
}

.telephone_number p {
    margin-bottom: 10px;
}

@media (min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media (max-width: 1280px) and (min-width: 768px) {
    .container {
        max-width: 90%;
    }
}

@media (max-width: 767px) and (min-width: 340px) {
    .container {
        max-width: 95%;
    }

}