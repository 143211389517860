.wrapper {
    position: fixed;
    background-color: #1C1D1F;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 110;
}

.container {
    width: 40%;
    min-height: 600px;
    background-color: #FFFFFF1A;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    padding: 38px 48px;
    transition: 0.4s;
}

.title {
    font-size: 48px;
    font-weight: 600;
    font-family: 'PoppinsSemiBold', sans-serif;
    color: #fff;
    text-align: center;
    margin-bottom: 40px;
}

.google_wrapper {
    width: 100%;
}

.google_btn {
    width: 100%;
    height: 64px;
    border-radius: 16px;
    background-color: transparent;
    border: 2px solid #FFFFFF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    transition: 0.3s;
}

.google_btn:active {
    border: 2px solid #986BE2;
}

.via_email {
    font-family: 'InterMedium', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #B1B1B5;
}



.inputs_block {
    width: 100%;
}

.inputs {
    font-family: 'InterSemiBold', sans-serif;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    display: flex;
    width: 100%;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #3A3B3D;
    letter-spacing: 1px;
    border-radius: 16px;
    border: none;
    transition: 0.3s;
    margin-bottom: 20px;
}

.inputs:focus {
    outline: 1px solid #986BE2;
}

.continue_btn {
    width: 100%;
    height: 64px;
    margin: 20px 0 0;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    background-color: #986BE2;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    transition: 0.1s;
}

.continue_btn:active {
    background-color: transparent;
    border: 2px solid #986BE2;
}

.text_info {
    color: #B1B1B5;
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
}

.text_info a {
    text-decoration: none;
}

.pass_text {
    width: 100%;
    display: block;
    color: #fff;
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
}

.error {
    color: rgb(255, 0, 0);
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 13px;
    display: block;
    width: 100%;
    margin: 10px 0;
    text-align: center;
}

.login_text {
    text-align: center;
    margin-bottom: 20px;
    color: #B1B1B5;
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 13px;
}

.redirect {
    cursor: pointer;
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
}

.show_pass {
    display: block;
    text-align: center;
    color: #B1B1B5;
    font-family: 'PoppinsRegular', sans-serif;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    margin-top: 10px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.show_pass_inp {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

#inputPreview {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.show_pass_inp+label {
    position: relative;
    font-size: 14px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    height: 20px;
    color: rgb(177, 177, 181);
}

.show_pass_inp+label::before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px;
    width: 14px;
    height: 14px;
    background-color: rgb(59, 59, 59);
    border-width: 3px;
    border-style: solid;
    border-color: rgb(58, 59, 61);
    border-radius: 0px;
    box-shadow: rgba(153, 107, 226, 0.75) 0px 0px 7px 0px;
    margin-right: 10px;
}

.show_pass_inp:checked+label::after {
    content: " ";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjOTk2YmUyIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+");
    background-repeat: no-repeat;
    background-size: 10px 10px;
    background-position: center center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    left: 0px;
    top: 0px;
    text-align: center;
    background-color: transparent;
    font-size: 10px;
    height: 20px;
    width: 20px;
}

@media(max-width: 1024px) {
    .container {
        padding: 28px 18px;
    }

    .title {
        font-size: 38px;
    }
}

@media(max-width: 992px) {
    .container {
        width: 80%;
    }
}

@media(max-width: 767px) {
    .container {
        padding: 28px 15px;
        width: 85%;
    }
}

@media(max-width: 430px) {
    .container {
        width: 95%;
    }
}