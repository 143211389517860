.technology_block {
    display: flex;
    flex-wrap: wrap;
    border: none;
    gap: 1%;
}

.technology_item {
    border-radius: 10px;
    background: #76797D;
    padding: 6px 12px;
    margin: 6px 0;
}