.question_container {
    border-top: 1px solid #3A3B3D;
    border-bottom: 1px solid #3A3B3D;
}

.question {
    padding: 22px 0;
    color: #F3F4F8;
    font-family: 'PoppinsRegular', sans-serif;
    font-weight: 600;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.answer {
    padding: 22px 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    padding: 12px;
    color: #B1B1B5;
    font-family: 'PoppinsRegular', sans-serif;
    font-weight: 400;

    transition: 0.8s;
}

.answerOpen {
    padding: 22px 0;
    visibility: visible;
    height: auto;
    overflow: auto;
    padding: 12px;
    color: #B1B1B5;
    font-family: 'PoppinsRegular', sans-serif;
    font-weight: 400;
    transition: 0.8s;
}

.arrow_down {
    object-fit: contain;
    object-position: center;
    cursor: pointer;

    transition: 0.8s;

}

.arrow_up {
    object-fit: contain;
    object-position: center;
    cursor: pointer;

    transition: 0.8s;
    transform: rotate(180deg);
}