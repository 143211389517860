.container {
    max-width: 85%;
    margin: 0 auto;
}

.goBack_title_block {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 30px 0;
}

.goBack_title_block img {
    cursor: pointer;
    border-radius: 50%;
    background-color: #5c5e626e;
    border: 1px solid #5c5e62;
}

.title {
    font-family: 'PoppinsSemiBold', sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #F3F4F8;
}

.video_block {
    display: flex;
    justify-content: space-between;
}

.video_wrapper {
    flex: 0 1 65%;
    border-radius: 10px;
    overflow: hidden;
}

.playBtn {
    background-color: #986BE2 !important;
    box-shadow: 0px 0px 35px 5px #986BE2;
    border: none !important;
}

.rigth_side {
    flex: 0 1 33%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.add_in_whishlist {
    cursor: pointer;
    padding: 14px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.10);
    border: none;
    width: 100%;
    color: #FAF6FF;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.add_in_whishlist:disabled {
    opacity: 0.5;
    cursor: default;
}

.add_in_whishlist:active {
    border: 1px solid rgba(0, 0, 0, 0.679);
    box-shadow: inset 0px 0px 7px 2px rgba(0, 0, 0, 0.622);
}

.info_wrapper {
    margin-top: 96px;
    display: flex;
    justify-content: space-between;
}

.description_block {
    flex: 0 1 65%;
}

.info_title {
    color: #F3F4F8;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    font-size: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #B1B1B5;
}

.description_wrapper {
    background-color: #2B2C2E;
    border-radius: 12px;
    padding: 10px 8px 20px 20px;
    margin: 24px 0 50px;
}

.description {
    white-space: break-spaces;
    font-family: 'InterMedium', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #B1B1B5;
    letter-spacing: 0.14px;
    line-height: 28px;
    margin: 24px 0;
}

.description a {
    color: rgb(108, 108, 244);
    font-size: 14px;
    font-family: 'InterMedium', sans-serif;
    font-weight: 500;
    letter-spacing: 0.8px;
    cursor: pointer;
}

.description a:link {
    color: rgb(108, 108, 244);
}

.description a:visited {
    color: rgb(175, 223, 87);
}

.more_btn {
    cursor: pointer;
    color: #986BE2;
    font-family: 'InterMedium', sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.14px;
    line-height: 28px;
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width: 1440px) {
    .video_wrapper {
        flex: 0 0 65%;
    }

    .info_wrapper {
        margin-top: 20px;
    }
}


@media(max-width: 992px) {
    .video_block {
        flex-direction: column;
    }

    .video_wrapper {
        margin-bottom: 20px;
    }

    .description_block {
        flex: 0 1 100%;
    }
}

@media(max-width: 767px) {
    .container {
        max-width: 95%;
    }

    .title {
        font-size: 26px;
    }

    .playBtn {
        box-shadow: 0px 0px 15px 5px #986BE2;
    }
}