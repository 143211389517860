.container {
    max-width: 85%;
    margin: 0 auto;
}

.title_block {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 40px 0 64px;
    user-select: none;
}

.back_img {
    cursor: pointer;
    border-radius: 50%;
    background-color: #5c5e626e;
    border: 1px solid #5c5e62;
}

.title {
    font-family: 'PoppinsSemiBold', sans-serif;
    font-size: 40px;
    font-weight: 600;
    color: #F3F4F8;
    line-height: 48px;
}

.subtitle_block {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
}

.subtitle_block h4 {
    font-family: 'InterMedium', sans-serif;
    font-weight: 500;
    font-size: 32px;
    color: #B1B1B5;
    line-height: 38px;
    letter-spacing: 0.96px;
}

.subtitle_block h2 {
    font-family: 'InterSemiBold', sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #FFF;
    line-height: 38px;
    letter-spacing: 0.96px;
}

.choose_wrapper {
    width: 100%;
    min-height: 300px;
    background-color: #2B2C2E;
    border-radius: 20px;
    padding: 50px 50px 40px 50px;

}

.choose_container {
    display: flex;
    justify-content: space-between;
}

.courses {
    flex: 0 1 47%;
}

.courses h3 {
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.96px;
    color: #fff;
    margin-bottom: 15px;
}

.booking_btn {
    display: block;
    width: 30%;
    height: 56px;
    margin: 50px auto 30px;
    background-color: #986BE2;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    transition: 0.3s;
}

.booking_btn:active {
    background-color: transparent;
    border: 2px solid #986BE2;
    color: #986BE2;
}

.error {
    color: rgb(255, 0, 0);
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 13px;
    display: block;
    width: 100%;
    margin: 10px 0;
    text-align: center;
}

.city_block {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
}

.cities_title {
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 22px;
    color: var(--light-gray);
}

.select_city {
    color: #000;
}

.select_city {
    font-family: 'InterBold', sans-serif;
    font-weight: 500;
    font-size: 20px;
    display: inline-block;
    width: 170px;
    cursor: pointer;
    padding: 10px 15px;
    outline: 0;
    border: 0px solid #000000;
    border-radius: 0px;
    background: #2B2C2E;
    color: #F3F4F8;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 6px;
}

.select_city::-ms-expand {
    display: none;
    border: none;
    outline: none;
}

.first {
    color: #929293;
}

.select_city:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.empty {
    text-align: center;
    font-family: 'PoppinsMedium', sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin-top: 100px;
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width: 767px) {
    .container {
        max-width: 95%;
    }

    .title {
        font-size: 30px;
    }

    .subtitle_block h4,
    .subtitle_block h2 {
        font-size: 25px;
    }

    .choose_wrapper {
        margin-bottom: 40px;
    }

    .choose_container {
        flex-direction: column;
    }

    .online_block {
        border-bottom: 1px solid #5c5e62;
        margin-bottom: 20px;
    }

    .booking_btn {
        width: 100%;
    }
}

@media(max-width: 500px) {
    .title_block {
        gap: 5px;
    }

    .title {
        font-size: 26px;
    }

    .choose_wrapper {
        padding: 20px 15px;
    }

    .city_block {
        flex-direction: column;
        align-items: flex-start;
    }
}