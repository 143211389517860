.description_section {
    display: flex;
    align-items: center;
    font-family: 'PoppinsRegular', sans-serif;
    min-height: 100vh;
    overflow: hidden;
}

.container {
    max-width: 85%;
    margin: 0 auto;
}


.card {
    display: flex;
    align-items: center;
    background: #2B2C2E;
    border: none;
    border-radius: 24px;
    position: relative;
    z-index: 1;
}

.card::before {
    content: '';
    position: absolute;
    left: 5%;
    top: 0%;
    width: 30%;
    height: 60%;
    background: linear-gradient(135deg, #AE80DC 0%, #DC83C3 47.77%, #8084DC 100%);
    /* opacity: 50%; */
    border-radius: 50%;
    filter: blur(80px);
    z-index: -10;
    animation: gradient 5s linear infinite;
}

@keyframes gradient {

    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg) scale(1.2);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

.dark_bg {
    border-radius: 24px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 3;
}

.leftSide {
    z-index: 4;
    flex: 0 1 40%;
    padding-left: 100px;
}

.section_title {
    color: var(--orange);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.title_description {
    color: #FFF;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 24px;
}

.description {
    color: var(--grey);
    font-family: 'InterRegular', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.rightSide {
    flex: 0 1 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -50px;
    left: 0%;
    z-index: 4;
    padding: 10px;
    overflow: hidden;

}

.rightSideImage {
    width: 100%;
    object-fit: cover;
    object-position: center;
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width: 1280px) {
    .title_description {
        font-size: 38px;
    }

    .description {
        font-size: 14px;
    }
}

@media(max-width: 992px) {
    .leftSide {
        flex: 0 1 48%;
    }

    .title_description {
        font-size: 30px;
        line-height: 30px;
    }

    .description {
        line-height: 21px;
    }
}

@media(max-width: 767px) {
    .description_section {
        min-height: 90vh;
    }

    .container {
        max-width: 100%;
    }

    .card {
        flex-direction: column-reverse;
    }

    .leftSide {
        padding: 0 20px 40px;
    }

}