.list_wrapper {
    background-color: #2B2C2E;
    border-radius: 10px;
    padding: 20px;
    height: 500px;
    margin-bottom: 20px;
}

.wrapper_links {
    height: 80%;
    overflow-y: scroll;
}

.wrapper_links::-webkit-scrollbar {
    width: 10px;
}

.wrapper_links::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
}

.wrapper_links::-webkit-scrollbar-thumb {
    background-color: #B1B1B5;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 12px;
}

.wrapper_links::-webkit-scrollbar-thumb:hover {
    background-color: #6d6d6e;
}

.list_title {
    font-family: 'InterBold', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    line-height: 30px;
    border-bottom: 1px solid #4F4B56;
    padding-bottom: 30px;
    margin-bottom: 20px;
}

.video_link {
    display: block;
    text-decoration: none;
    margin: 20px 0;
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 28.8px;
    color: #fff;
}

.active_video {
    padding-left: 10px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.3);
}

@media(max-width: 1440px) {
    .list_wrapper {
        height: 400px;
    }


}

@media(max-width: 992px) {
    .list_wrapper {
        height: 350px;
        overflow: hidden;
        padding-top: 50px;
        padding-bottom: 50px;

    }
}

@media(max-width: 767px) {
    .list_title {
        padding-bottom: 15px;
    }
}

@media(max-width: 430px) {
    .list_wrapper {
        height: 300px;
    }

    .video_link {
        margin: 10px 0;
    }

    .video_link:last-child {
        margin-bottom: 80px;
    }
}