.hero_section {
    height: 100vh;
    position: relative;
    font-family: 'InterRegular', sans-serif;

}

.hero_section::before {
    content: '';
    position: absolute;
    left: 5%;
    top: 10%;
    width: 40%;
    height: 80%;
    background: linear-gradient(135deg, #AE80DC 0%, #DC83C3 47.77%, #8084DC 100%);
    opacity: 50%;
    backdrop-filter: blur(4px);
    border-radius: 50%;
    filter: blur(50px);
    z-index: -10;
    animation: gradient 5s linear infinite;
}

@keyframes gradient {

    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg) scale(1.2);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

.dark_bg {
    background-color: rgba(0, 0, 0, 0.552);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.container {
    max-width: 85%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 3;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftSide {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    padding: 10% 0;
}

.block {
    flex: 0 1 45%;
}

.main_title {
    z-index: 1;
    font-size: 72px;
    font-weight: 600;
    line-height: 80px;
    font-family: 'PoppinsRegular', sans-serif;
    font-weight: 700;
}

.title_description {
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    color: var(--grey);
    margin: 20px 0 30px;
    max-width: 80%;
}

.main_section_buttons {
    display: flex;
    justify-content: space-between;
}

.whatchAPresentation {
    background-color: var(--violet);
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 16px 30px;
    text-align: center;
    border: 1px solid var(--violet);
    border-radius: 20px;
    flex: 0 1 50%;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.whatchAPresentation:active {
    background-color: transparent;
    border: 1px solid #986BE2;
    color: #986BE2;
}

.rightSide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.technologyIcon {
    width: 400px;
    display: block;
    margin: 0 auto;
    object-fit: cover;
    object-position: center;
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width: 1280px) {
    .main_title {
        font-size: 60px;
    }

    .main_section_buttons {
        gap: 20px;
    }

    .leaveAReq_btn {
        padding: 16px 10px;
    }

    .whatchAPresentation {
        padding: 16px 10px;
    }
}

@media(max-width: 1024px) {
    .main_title {
        font-size: 40px;
        line-height: 50px;
    }

    .main_section_buttons {
        flex-direction: column;
    }

    .leaveAReq_btn {
        width: 100%;
    }

    .title_description {
        max-width: 100%;
        font-size: 16px;
    }

    .technologyIcon {
        width: 300px;
    }
}

@media(max-width: 767px) {
    .hero_section {
        overflow: hidden;
    }

    .container {
        flex-direction: column-reverse;
    }

    .leaveAReq_btn {
        padding: 12px 24px;
    }

    .hero_section::after {
        width: 280px;
        height: 200px;
        top: 40%;
    }

    .leftSide {
        padding: 0;
    }

    .rightSide {
        margin: 20px 0 0;
    }

    .dark_bg {
        background-color: rgba(0, 0, 0, 0.5);
    }


}