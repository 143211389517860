.callback_section {
    min-height: 100vh;
    position: relative;
    z-index: 1;
    font-family: 'PoppinsRegular', sans-serif;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #2B2C2E;
    padding: 100px 0;
}

.callback_section::before {
    content: '';
    position: absolute;
    right: 0%;
    bottom: -20%;
    width: 45%;
    height: 90%;
    background: linear-gradient(135deg, #AE80DC 0%, #DC83C3 47.77%, #8084DC 100%);
    mix-blend-mode: overlay;
    border-radius: 50%;
    filter: blur(50px);
    z-index: -10;
    animation: gradient 5s linear infinite;
}

@keyframes gradient {

    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg) scale(1.2);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

.dark_bg {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
}

.container {
    flex: 0 1 100%;
    position: relative;
    z-index: 3;
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.escalator {
    width: 100%;
}

.rightSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section_title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-bottom: 12px;
    color: #F1973D;
}

.title_description {
    font-size: 43px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    color: #FFF;
    margin-bottom: 8px;
}

.description {
    font-family: 'InterRegular', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #B1b1b5;
}

.actions {
    margin-top: 32px;
}

.callback_form {
    display: flex;
    flex-direction: column;
}

.email_inp {
    margin-bottom: 18px;
}

.input_name {
    font-family: 'InterRegular', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #B1B1B5;
    margin-bottom: 12px;
}

.callback_input {
    padding: 20px 24px;
    color: #B1B1B5;
    max-width: 100%;
    border: none;
    border-radius: 16px;
    background-color: #2B2C2E;
    font-family: 'InterRegualr', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    outline: none;
}

.callback_input::placeholder {
    color: #76797D;
}

.callback_input:focus {
    outline: 1px solid #986BE2;
}

.textarea_inp {
    resize: none;
    margin-bottom: 24px;
    height: 140px;
}


.submit_btn {
    padding: 16px 24px;
    background-color: #986BE2;
    color: #FFFFFF;
    max-width: 50%;
    border: none;
    border-radius: 16px;
    font-family: 'InterRegular', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;
}

.submit_btn:active {
    background-color: transparent;
    border: 1px solid #986BE2;
}

.phone_inp {
    margin-bottom: 20px;
}

.error {
    color: rgb(255, 0, 0);
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 13px;
    display: block;
    width: 100%;
    margin: 0px 0 20px;
    text-align: center;
}

.name_inp {
    margin-bottom: 20px;
}

@media (min-width: 1921px) {

    .container {
        max-width: 1440px;
    }

}

@media (max-width: 767px) and (min-width: 340px) {
    .container {
        max-width: 95%;
        flex-direction: column;
    }

    .section_title {
        font-size: 14px;
        letter-spacing: 0.7px;
    }

    .title_description {
        font-size: 24px;
        line-height: 36px;
    }

    .description {
        align-self: stretch;
        font-weight: 400;
    }

    .actions {
        margin-top: 32px;
    }

    .email_inp {
        margin-bottom: 18px;
    }

    .input_name {
        font-weight: 400;
    }

    .submit_btn {
        max-width: 100%;
    }
}