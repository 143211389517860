.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    backdrop-filter: blur(10px);
}

.container {
    width: 511px;
    height: 224px;
    background-color: #2B2C2E;
    border-radius: 32px;
    border: 2px solid rgba(255, 255, 255, 0.10);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.25);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 27px 40px 40px;
}

.title {
    font-family: 'InterSemiBold', sans-serif;
    font-weight: 600;
    color: #F3F4F8;
    font-size: 20px;
}

.question {
    font-family: 'InterRegular', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #B1B1B5;
}

.btns_wrapper {
    display: flex;
    justify-content: space-between
}

.btns_wrapper button {
    flex: 0 1 48%;
    height: 48px;
    border-radius: 8px;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
}

.cancel {
    background-color: transparent;
    border: 1px solid #B1B1B5;
    color: #B1B1B5;
}

.quit {
    border: none;
    background-color: #ED5158;
    color: #fff;
}