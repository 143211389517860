.container {
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.condition_title {
    margin: 24px 0;
    text-align: center;
    font-size: 40px;
    font-family: 'InterBold', sans-serif;
    font-weight: 700;
    color: #fff;
}

.condition_content {
    margin: 30px 0;
    white-space: break-spaces;
    line-height: 24px;
    font-family: 'InterMedium', sans-serif;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
}

@media (min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media (max-width: 1280px) and (min-width: 768px) {
    .container {
        max-width: 90%;
    }
}

@media (max-width: 767px) and (min-width: 340px) {
    .container {
        max-width: 95%;
    }

    .condition_title {
        font-size: 30px;
    }

}