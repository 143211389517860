.wrapper {
    background-color: #1C1D1F;
}

.container {
    max-width: 85%;
    margin: 0 auto;
}

.title_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 68px 0;
}

.title_block h2 {
    font-family: 'PoppinsSemiBold', sans-serif;
    font-size: 40px;
    font-weight: 600;
    color: #F3F4F8;
    line-height: 48px;
}

.title_block a {
    border: 1px solid #986BE2;
    color: #fff;
    background: transparent;
    border-radius: 16px;
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}

@media(min-width: 1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width: 767px) {
    .container {
        max-width: 95%;
    }

    .title_block h2 {
        font-size: 30px;
    }
}

@media(max-width: 500px) {
    .title_block {
        flex-direction: column-reverse;
        margin: 30px 0;
    }

    .title_block h2 {
        margin: 20px 0;
        border-bottom: 1px solid #434448;
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
    }

    .title_block a {
        width: 100%;
        justify-content: center;
    }
}