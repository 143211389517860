* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  /* background-color: #2B2C2E; */
  background: #1C1D1F;

}