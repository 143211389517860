.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.container {
    width: 400px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #2B2C2E;
    border-radius: 24px;
    position: relative;
    padding: 20px;
}

.close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
}

.container a {
    display: flex;
    align-items: center;
    font-family: 'PoppinsMedium', sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
}

@media(max-width: 440px) {
    .container {
        width: 95%;
    }
}